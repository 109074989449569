import { GuidelinesIndex } from '../model/GuidelinesIndex';
import { ResourcePathAndUrl } from './FileSystemGuidelinesLoader';
import { GuidelinesAndSource } from './GuidelinesProvider';

export interface GuidelinesLoaderProvider {
  loadGuidelinesIndex: (showAbout?: boolean) => Promise<GuidelinesIndex>;
  loadGuideline: (guidelineKey: string) => Promise<GuidelinesAndSource>;
  getUriTransformer: (sourcePath: string) => (uri: string) => string;
  loadAboveTheFoldGuideline: () => Promise<GuidelinesAndSource>;
  getEndsWithUriTransformer?: (sourcePath: string) => (uri: string) => string[];
  resourcesUrlsBySourcePath?: Map<string, ResourcePathAndUrl[]>;
  staticAssetsUrl?: string;
}

export const EmptyGuidelineLoaderProvider: GuidelinesLoaderProvider = {
  loadGuidelinesIndex: (): Promise<GuidelinesIndex> =>
    new Promise((resolve, reject) => {
      reject(new Error('No GuidelineProvider to load index.'));
    }),
  loadGuideline: (guidelineKey: string): Promise<GuidelinesAndSource> =>
    new Promise((resolve, reject) => {
      reject(
        new Error(`No GuidelineProvider to load guideline: ${guidelineKey}`)
      );
    }),
  getUriTransformer: (): ((uri: string) => string) => (uri: string) => uri,
  loadAboveTheFoldGuideline: (): Promise<GuidelinesAndSource> =>
    new Promise((resolve, reject) => {
      reject(
        new Error(`No GuidelineProvider to load 'above the fold'message.`)
      );
    }),
};
