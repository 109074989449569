import { XCircleIcon } from '@heroicons/react/20/solid';
import { useSearchParams } from 'react-router-dom';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';

type ImgErrorAlertProps = {
  imgErrorUris: string[];
};

export function ImgErrorAlert({
  imgErrorUris,
}: ImgErrorAlertProps): JSX.Element {
  const { endsWithUriTransformer } = useGuidelinesProviderContext();

  const [searchParams] = useSearchParams();

  const hasShowErrorsParam = searchParams.get('showErrors') === 'true';

  const showErrors = hasShowErrorsParam || endsWithUriTransformer;

  if (!showErrors) return <div />;

  if (imgErrorUris.length === 0) return <div data-cy="hasImageErrors: false" />;

  return (
    <div className="rounded-md bg-red-50 p-4" data-cy="hasImageErrors: true">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="clear-styles">
            <div className="text-sm font-medium text-red-800">
              These images could not be loaded:
            </div>
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul
              className="list-disc space-y-1 pl-5"
              // style={{ margin: 'unset' }}
            >
              {imgErrorUris.map((uri) => {
                const displayUri = uri.replace(/https:\/\/s3.*\/public\//, '');
                const haveSuggestedUris =
                  endsWithUriTransformer &&
                  endsWithUriTransformer(displayUri).length > 0;
                if (haveSuggestedUris) {
                  return (
                    <li key={uri} className="tailwind">
                      {displayUri} - did you mean:
                      <ul className="pl-5">
                        {endsWithUriTransformer(uri).map((suggestedUri) => (
                          <li key={suggestedUri} className="tailwind">
                            {suggestedUri}
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                }
                return (
                  <li key={displayUri} className="tailwind">
                    {displayUri}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
