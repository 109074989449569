import { markdownToPages } from '../guideline_parser/GuidelineParser';
import {
  FileSystemGuidelinesIndexEntry,
  GuidelinesIndex,
  GuidelinesIndexEntry,
} from '../model/GuidelinesIndex';
import { GuidelinesAndSource } from './GuidelinesProvider';

export async function loadGuidelinesIndexFromFileSystem(
  rootPath: FileSystemDirectoryHandle
) {
  return findGuidelines(rootPath);
}

export async function loadRawGuidelinesIndexFromFileSystem(
  rootPath: FileSystemDirectoryHandle
) {
  return findNestedGuidelines(rootPath, '');
}

async function findNestedGuidelines(
  directoryHandle: FileSystemDirectoryHandle,
  parentPath: string
) {
  const allGuidelines: FileSystemGuidelinesIndexEntry[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for await (const entry of directoryHandle.values()) {
    const isHiddenFile =
      entry.name.startsWith('.') ||
      entry.name === 'README.md' ||
      entry.name.endsWith('-media');
    const isDirectory = entry.kind === 'directory';
    const isMarkdownFile = entry.name.endsWith('.md');
    const isPdfFile = entry.name.endsWith('.pdf');

    if (!isHiddenFile) {
      if (isDirectory) {
        const newParentPath =
          parentPath.length > 0
            ? `${parentPath}/${entry.name}`
            : `${entry.name}`;
        allGuidelines.push(
          ...(await findNestedGuidelines(entry, newParentPath))
        );
      } else if (isMarkdownFile || isPdfFile) {
        const guidelineName = entry.name.replace('.md', '').replace('.pdf', '');
        const guidelineModule = `${parentPath}`;
        const path = `${guidelineModule}/${guidelineName}`;
        const guidelineIndexEntry: FileSystemGuidelinesIndexEntry = {
          key: path,
          md5: '',
          module: guidelineModule,
          guidelineName,
          path,
          extension: getFileExtension(entry.name),
          fileHandle: entry,
          parentFolderHandle: directoryHandle,
          metadata: new Map(),
        };
        allGuidelines.push(guidelineIndexEntry);
      }
    }
  }

  return allGuidelines;
}

async function findGuidelines(directoryHandle: FileSystemDirectoryHandle) {
  const allGuidelines = await findNestedGuidelines(directoryHandle, '');

  const noDuplicatePdfGuidelines = filterOutPdfDuplicates(allGuidelines);

  const sortedGuidelines = noDuplicatePdfGuidelines.sort((a, b) => {
    const moduleComparison = a.module.localeCompare(b.module);
    if (moduleComparison !== 0) return moduleComparison;
    return a.guidelineName.localeCompare(b.guidelineName);
  });

  const guidelinesIndex: GuidelinesIndex = {
    indexEntries: sortedGuidelines,
    lastModified: await findLatestModified(sortedGuidelines),
    guidelineCount: findGuidelineCount(sortedGuidelines),
  };

  return guidelinesIndex;
}

function filterOutPdfDuplicates(
  allGuidelines: FileSystemGuidelinesIndexEntry[]
) {
  const markdownGuidelines = allGuidelines.filter(
    (entry) => entry.extension === 'md'
  );
  const pdfGuidelines = allGuidelines.filter(
    (entry) => entry.extension === 'pdf'
  );
  const noDuplicatePdfGuidelines = pdfGuidelines.filter((entry) => {
    const { module, guidelineName } = entry;
    const matchingMarkdownGuideline = markdownGuidelines.find(
      (markdownEntry) =>
        markdownEntry.module === module &&
        markdownEntry.guidelineName === guidelineName
    );
    return matchingMarkdownGuideline === undefined;
  });
  // return [...noDuplicatePdfGuidelines]; // For finding lone PDFs
  return [...markdownGuidelines, ...noDuplicatePdfGuidelines];
}

function getFileExtension(filename: string) {
  return filename.substring(filename.lastIndexOf('.') + 1) || filename;
}

export interface ResourcePathAndUrl {
  path: string;
  url: string;
}

async function findResources(
  directoryHandle: FileSystemDirectoryHandle,
  rootDirectoryHandle = directoryHandle
): Promise<ResourcePathAndUrl[]> {
  const allResources: ResourcePathAndUrl[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for await (const entry of directoryHandle.values()) {
    const isHiddenFile = entry.name.startsWith('.');
    const isDirectory = entry.kind === 'directory';
    const isMarkdownFile = entry.name.endsWith('.md');

    if (!isHiddenFile) {
      if (isDirectory) {
        allResources.push(...(await findResources(entry, rootDirectoryHandle)));
      } else if (!isMarkdownFile) {
        const allFolders = await rootDirectoryHandle.resolve(entry);
        if (!allFolders) return allResources;
        const path = allFolders.join('/');
        const url = URL.createObjectURL(await entry.getFile());
        allResources.push({ path, url });
      }
    }
  }
  return allResources;
}

export async function findResourceUrlsBySourcePath(
  rootPath: FileSystemDirectoryHandle
): Promise<Map<string, ResourcePathAndUrl[]>> {
  const sourcePathToResourceUrlMap = new Map<string, ResourcePathAndUrl[]>();

  // eslint-disable-next-line no-restricted-syntax
  for await (const entry of rootPath.values()) {
    const isHiddenFile = entry.name.startsWith('.');
    const isDirectory = entry.kind === 'directory';

    if (!isHiddenFile && isDirectory) {
      // recurse through this directory, building a path for every resource and saving it and the file handle
      const sourcePath = entry.name;
      const allResources = await findResources(entry);
      sourcePathToResourceUrlMap.set(sourcePath, allResources);
    }
  }
  return sourcePathToResourceUrlMap;
}

export async function loadGuidelineMarkdownFromFileSystem(
  fileHandle: FileSystemFileHandle
): Promise<string> {
  const guidelineFile = await fileHandle.getFile();
  const guidelineText = await guidelineFile.text();
  return guidelineText;
}

export async function loadGuidelineFromFileSystem(
  guidelineIndexToOpen: GuidelinesIndexEntry
): Promise<GuidelinesAndSource> {
  const { fileHandle } = guidelineIndexToOpen as FileSystemGuidelinesIndexEntry;

  const extension = getFileExtension(fileHandle.name).toLowerCase();
  if (extension === 'pdf') {
    return buildPdfWrapper(fileHandle.name, guidelineIndexToOpen.module);
  }

  const guidelineText = await loadGuidelineMarkdownFromFileSystem(fileHandle);
  const pages = markdownToPages(guidelineText);
  return {
    pages,
    fileHandle,
    key: guidelineIndexToOpen.key,
  };
}

function buildPdfWrapper(
  filename: string,
  module: string
): GuidelinesAndSource {
  const baseFilename = filename.substring(0, filename.lastIndexOf('.'));
  const isInSubFolder = module.includes('/');
  const path = isInSubFolder
    ? `${module.substring(module.indexOf('/') + 1)}/`
    : '';
  const fullPath = `${path}${filename}`;
  const pdfWrapperMarkdown = `# ${baseFilename}\n>> View PDF [target:${fullPath}]
<<`;

  const pdfWrapperGuideline = markdownToPages(pdfWrapperMarkdown);
  return { pages: pdfWrapperGuideline, key: fullPath };
}

async function findLatestModified(
  sortedGuidelines: FileSystemGuidelinesIndexEntry[]
): Promise<Date> {
  let latest = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const current of sortedGuidelines) {
    // eslint-disable-next-line no-await-in-loop
    const file = await current.fileHandle.getFile();
    const currentModified = file.lastModified;
    if (currentModified > latest) latest = currentModified;
  }

  return new Date(latest);
}

function findGuidelineCount(
  sortedGuidelines: FileSystemGuidelinesIndexEntry[]
) {
  const guidelinesToCount = sortedGuidelines.filter(
    (indexEntry) => !indexEntry.path.startsWith('About/')
  );

  return guidelinesToCount.length;
}
