import { buildGuidelinesMap } from '../guideline_parser/GuidelineParser';
import {
  GuidelinePage,
  GuidelinePages,
  GuidelineSection,
} from '../model/GuidelinePage';

export interface GuidelinesProvider {
  guidelines: GuidelinesAndSource;
  setGuidelines: (guidelines: GuidelinesAndSource) => void;
  uriTransformer: (uri: string) => string;
  endsWithUriTransformer?: (uri: string) => string[];
  getTargetUrlSection: (targetUrl: string) => GuidelineSection;
  pageId?: string;
  singlePageRender?: boolean;
  metaData?: Map<string, string>;
  textReplacements?: Map<string, string>;
  targetUrl?: string;
}

export interface GuidelinesAndSource {
  pages: GuidelinePages;
  fileHandle?: FileSystemFileHandle;
  key: string;
}

const emptyGuidelinePage: GuidelinePage = {
  id: 'root',
  title: '',
  sections: [],
};
const guidelinesMap = buildGuidelinesMap([emptyGuidelinePage]);
export const emptyGuidelinePages: GuidelinePages = {
  rootPage: emptyGuidelinePage,
  allPagesById: guidelinesMap,
};

const emptySection: GuidelineSection = {
  content: '',
};

export const EmptyGuidelinesProvider: GuidelinesProvider = {
  guidelines: {
    pages: emptyGuidelinePages,
    key: '',
  },
  setGuidelines: () => {
    /* do nothing */
  },
  uriTransformer: (uri: string) => uri,
  getTargetUrlSection: () => emptySection,
};
