import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Typography } from '@mui/material';
import ReactGA from 'react-ga4';
import { Suspense, lazy, useEffect } from 'react';
import { Contents } from './components/Contents';
import { Guideline } from './components/guideline_display/Guideline';
import { AdminDashboard } from './components/admin/AdminDashboard';
import { NewGuidelineTemplates } from './components/admin/NewGuidelineTemplates';
import { GuidelinesLoaderProviderContext } from './services/GuidelinesLoaderProviderContext';
import { buildS3GuidelinesProvider } from './services/S3GuidelinesProvider';
import { Guidelines } from './components/Guidelines';
import { ThemeAndTitleProvider } from './components/ThemeAndTitleProvider';
import { WordImporter } from './components/authoring/WordImporter';
import { GuidelineLoader } from './components/GuidelineLoader';
import { ColourGrid } from './components/routes/ColourGrid';
import { ScrollToTop } from './components/ScrollToTop';
import { PlaceholderContents } from './components/PlaceholderContents';

// Strip out the authoring components to reduce bundle size
const AuthoringDashboard = lazy(
  () => import('./components/authoring/AuthoringDashboard')
);
const PreviewGuidelines = lazy(
  () => import('./components/authoring/PreviewGuidelines')
);
const EditableGuideline = lazy(
  () => import('./components/authoring/EditableGuideline')
);

export function App(): JSX.Element {
  const pagePath = window.location.pathname + window.location.search;
  useEffect(() => {
    const pageTitle = pageTitleFromUrl(pagePath);
    ReactGA.send({ hitType: 'pageview', page: pagePath, title: pageTitle });
  }, [pagePath]);

  if (process.env.NODE_ENV === 'development') {
    const warningMessages = buildWarningForMissingEnvironmentVariables();
    if (warningMessages) {
      return warningMessages;
    }
  }

  return (
    <ThemeAndTitleProvider>
      <div className="App" style={{ marginTop: 'env(safe-area-inset-top)' }}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route
                path="/"
                element={
                  <GuidelinesLoaderProviderContext.Provider
                    value={buildS3GuidelinesProvider(
                      process.env.REACT_APP_DEFAULT_S3_BUCKET ?? '',
                      process.env.REACT_APP_DEFAULT_S3_ROOT_PATH ?? ''
                    )}
                  >
                    <Guidelines />
                  </GuidelinesLoaderProviderContext.Provider>
                }
              >
                <Route index element={<Contents />} />
                <Route
                  path=":encodedUrl"
                  element={
                    <GuidelineLoader>
                      <Guideline />
                    </GuidelineLoader>
                  }
                />
              </Route>
              <Route
                path="staging"
                element={
                  <GuidelinesLoaderProviderContext.Provider
                    value={buildS3GuidelinesProvider(
                      process.env.REACT_APP_STAGING_S3_BUCKET ?? '',
                      process.env.REACT_APP_STAGING_S3_ROOT_PATH ?? ''
                    )}
                  >
                    <Guidelines />
                  </GuidelinesLoaderProviderContext.Provider>
                }
              >
                <Route index element={<Contents environment="staging" />} />
                <Route
                  path=":encodedUrl"
                  element={
                    <GuidelineLoader>
                      <Guideline />
                    </GuidelineLoader>
                  }
                />
              </Route>
              <Route
                path="workinprogress"
                element={
                  <GuidelinesLoaderProviderContext.Provider
                    value={buildS3GuidelinesProvider(
                      process.env.REACT_APP_WORK_IN_PROGRESS_S3_BUCKET ?? '',
                      process.env.REACT_APP_WORK_IN_PROGRESS_S3_ROOT_PATH ?? '',
                      'index-restricted.json'
                    )}
                  >
                    <Guidelines />
                  </GuidelinesLoaderProviderContext.Provider>
                }
              >
                <Route index element={<Contents environment="staging" />} />
                <Route
                  path=":encodedUrl"
                  element={
                    <GuidelineLoader>
                      <Guideline />
                    </GuidelineLoader>
                  }
                />
              </Route>
              <Route
                path="authoring"
                element={
                  <Suspense fallback={<PlaceholderContents />}>
                    <AuthoringDashboard />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<PlaceholderContents />}>
                      <PreviewGuidelines />
                    </Suspense>
                  }
                />
                <Route
                  path=":encodedUrl"
                  element={
                    <Suspense fallback={<PlaceholderContents />}>
                      <EditableGuideline />
                    </Suspense>
                  }
                />
                <Route
                  path="newguideline"
                  element={<NewGuidelineTemplates />}
                />
                <Route path="importworddocument" element={<WordImporter />} />
                <Route path="colourgrid" element={<ColourGrid />} />
                <Route
                  path="branchcomparisonreport"
                  element={
                    <GuidelinesLoaderProviderContext.Provider
                      value={buildS3GuidelinesProvider(
                        process.env.REACT_APP_DEFAULT_S3_BUCKET ?? '',
                        process.env.REACT_APP_DEFAULT_S3_ROOT_PATH ?? ''
                      )}
                    >
                      <Guidelines />
                    </GuidelinesLoaderProviderContext.Provider>
                  }
                >
                  <Route
                    path=":encodedUrl"
                    element={
                      <GuidelineLoader>
                        <Guideline />
                      </GuidelineLoader>
                    }
                  />
                </Route>
              </Route>
              <Route
                path="authoringhelp"
                element={
                  <GuidelinesLoaderProviderContext.Provider
                    value={buildS3GuidelinesProvider(
                      'uk.co.montreux.authoringhelp-json',
                      'main'
                    )}
                  >
                    <Guidelines />
                  </GuidelinesLoaderProviderContext.Provider>
                }
              >
                <Route index element={<Contents />} />
                <Route
                  path=":encodedUrl"
                  element={
                    <GuidelineLoader>
                      <Guideline />
                    </GuidelineLoader>
                  }
                />
              </Route>
              <Route path="admin" element={<AdminDashboard />}>
                <Route
                  path="reports"
                  element={
                    <Typography variant="h6" noWrap component="div">
                      Reports
                    </Typography>
                  }
                />
                <Route
                  index
                  element={
                    <Typography variant="h6" noWrap component="div">
                      Admin Dashboard
                    </Typography>
                  }
                />
              </Route>
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    </ThemeAndTitleProvider>
  );
}

function pageTitleFromUrl(pagePath: string): string {
  if (pagePath.length === 0 || pagePath === '/') {
    return 'Contents';
  }

  // View Regex with example: https://regex101.com/r/I37lA7/1
  const pathParsingRegex = /\/(?:(\w*)\/)?([^?]*)(?:\?pageId=(.*))?/;
  let [, environment, pageName, pageId] = pathParsingRegex.exec(pagePath) || [];
  environment = environment ?? '';
  pageName = pageName ?? '';
  pageId = pageId ?? '';

  if (pageName.length === 0) {
    return pagePath;
  }

  const subPageId = pageId.slice(pageId.indexOf('%2F'));
  const displayEnvironment = environment.length > 0 ? `${environment}/` : '';
  const displayPageId = subPageId ? decodeURIComponent(subPageId) : '';

  return `${displayEnvironment}${pageName}${displayPageId}`;
}

function buildWarningForMissingEnvironmentVariables(): JSX.Element | undefined {
  const {
    REACT_APP_CLIENT_ENV,
    REACT_APP_DEFAULT_S3_BUCKET,
    REACT_APP_DEFAULT_S3_ROOT_PATH,
    REACT_APP_STAGING_S3_BUCKET,
    REACT_APP_STAGING_S3_ROOT_PATH,
    REACT_APP_WORK_IN_PROGRESS_S3_BUCKET,
    REACT_APP_WORK_IN_PROGRESS_S3_ROOT_PATH,
  } = process.env;

  const missingEnvironmentVariables = [];
  if (!REACT_APP_CLIENT_ENV) {
    missingEnvironmentVariables.push('REACT_APP_CLIENT_ENV');
  }
  if (!REACT_APP_DEFAULT_S3_BUCKET) {
    missingEnvironmentVariables.push('REACT_APP_DEFAULT_S3_BUCKET');
  }
  if (!REACT_APP_DEFAULT_S3_ROOT_PATH) {
    missingEnvironmentVariables.push('REACT_APP_DEFAULT_S3_ROOT_PATH');
  }
  if (!REACT_APP_STAGING_S3_BUCKET) {
    missingEnvironmentVariables.push('REACT_APP_STAGING_S3_BUCKET');
  }
  if (!REACT_APP_STAGING_S3_ROOT_PATH) {
    missingEnvironmentVariables.push('REACT_APP_STAGING_S3_BUCKET');
  }
  if (!REACT_APP_WORK_IN_PROGRESS_S3_BUCKET) {
    missingEnvironmentVariables.push('REACT_APP_WORK_IN_PROGRESS_S3_BUCKET');
  }
  if (!REACT_APP_WORK_IN_PROGRESS_S3_ROOT_PATH) {
    missingEnvironmentVariables.push('REACT_APP_WORK_IN_PROGRESS_S3_ROOT_PATH');
  }

  if (missingEnvironmentVariables.length > 0) {
    const envReference = REACT_APP_CLIENT_ENV ?? <b>yourEnv</b>;
    const warningMessages = missingEnvironmentVariables.map((variableName) => (
      <Typography key={variableName}>
        {variableName} missing. Check .env.{envReference}
      </Typography>
    ));

    return <div className="App">{warningMessages}</div>;
  }
  return undefined;
}
