import { useState, useEffect } from 'react';

/**
 * Custom React hook that maintains state in browser history. The state will be
 * restored when navigating back to the page using the browser's back button,
 * but cleared when the page is refreshed or first loaded.
 *
 * @param initialState The initial state value.
 * @param key A unique key to associate with the state in the browser history.
 * @returns A tuple containing the current state and a function to update the
 * state.
 *
 * @template T The type of the state.
 */
export function useHistoryState<T>(
  initialState: T,
  key: string
): [T, (newState: T) => void] {
  // Initialize the state using the stored history state or the provided initialState.
  const [state, setState] = useState<T>(() => {
    const storedState = window.history.state && window.history.state[key];
    return storedState !== undefined ? storedState : initialState;
  });

  useEffect(() => {
    // Handler for the 'popstate' event to restore the state from history when navigating back.
    const handler = (event: PopStateEvent) => {
      if (event.state && event.state[key] !== undefined) {
        setState(event.state[key] as T);
      }
    };

    // Handler for the 'beforeunload' event to clear the history state when the page is refreshed.
    const beforeUnloadHandler = () => {
      window.history.replaceState(
        { ...window.history.state, [key]: undefined },
        ''
      );
    };

    // Add event listeners for 'popstate' and 'beforeunload' events.
    window.addEventListener('popstate', handler);
    window.addEventListener('beforeunload', beforeUnloadHandler);

    // Cleanup function to remove the event listeners when the component is unmounted.
    return () => {
      window.removeEventListener('popstate', handler);
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [key]);

  // Function to update the state and replace the history state with the new value.
  const setHistoryState = (newState: T) => {
    window.history.replaceState(
      { ...window.history.state, [key]: newState },
      ''
    );
    setState(newState);
  };

  // Return the state and the function to update it.
  return [state, setHistoryState];
}
