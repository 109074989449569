import {
  S3Client,
  PutObjectCommand,
  ListObjectsV2Command,
} from '@aws-sdk/client-s3';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import short from 'short-uuid';

export enum OperationState {
  NotStarted = 'NOT STARTED',
  InProgress = 'IN PROGRESS',
  CompletedSuccessfully = 'COMPLETED SUCCESSFULLY',
  Error = 'ERROR',
}

export interface UploadAndConversionState {
  upload: OperationState;
  conversion: OperationState;
  error?: Error;
}

export async function uploadAndConvertFile(
  fileToConvert: File,
  targetName: string,
  setState: (state: UploadAndConversionState) => void,
  setCompleted: (downloadUrl: string) => void
) {
  const trimmedTargetName = trimExcessSpaces(targetName);
  const keyPrefix = short.generate().toString();
  const uploadFileKey = `${keyPrefix}/${trimmedTargetName}`;

  setState({
    upload: OperationState.InProgress,
    conversion: OperationState.NotStarted,
    error: undefined,
  });
  try {
    await putFile(fileToConvert, uploadFileKey);
  } catch (error) {
    setState({
      upload: OperationState.Error,
      conversion: OperationState.NotStarted,
      error: error as Error,
    });
    return;
  }

  setState({
    upload: OperationState.CompletedSuccessfully,
    conversion: OperationState.InProgress,
    error: undefined,
  });

  const expectedOutputFileKey = uploadFileKey.replace('.docx', '.zip');

  try {
    const doesFileExist = await waitForFileToExist(expectedOutputFileKey, {
      maxWaitInSeconds: 120,
    });

    if (!doesFileExist) {
      setState({
        upload: OperationState.CompletedSuccessfully,
        conversion: OperationState.Error,
        error: new Error('Timed out'),
      });
      return;
    }
  } catch (error) {
    setState({
      upload: OperationState.CompletedSuccessfully,
      conversion: OperationState.Error,
      error: error as Error,
    });
    return;
  }

  setState({
    upload: OperationState.CompletedSuccessfully,
    conversion: OperationState.CompletedSuccessfully,
    error: undefined,
  });

  const downloadUrl = buildS3DownloadUrl(expectedOutputFileKey);
  setCompleted(downloadUrl);
}

// Set the AWS Region.
const Region = 'eu-west-1';
const InputBucket = 'uk.co.montreux.word-to-markdown-in';
const OutputBucket = 'uk.co.montreux.word-to-markdown-out';
// Create an Amazon S3 service client object.
const s3Client = new S3Client({
  region: Region,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: Region }),
    identityPoolId: 'eu-west-1:7fbc0643-369d-4cb1-9d51-7ddcb95ff4d5',
  }),
});

async function waitForFileToExist(
  expectedOutputName: string,
  options: { maxWaitInSeconds: number }
): Promise<boolean> {
  const pollingInterval = 5000;
  const maxRetries = (options.maxWaitInSeconds * 1000) / pollingInterval;

  let fileExists = await doesConvertedFileExist(expectedOutputName);
  let retries = 0;
  while (!fileExists && retries < maxRetries) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(5000);

    // eslint-disable-next-line no-await-in-loop
    fileExists = await doesConvertedFileExist(expectedOutputName);

    retries += 1;
  }

  return fileExists;
}

async function putFile(fileToUpload: File, name: string): Promise<void> {
  const s3Response = await s3Client.send(
    new PutObjectCommand({ Bucket: InputBucket, Key: name, Body: fileToUpload })
  );
  if (s3Response.$metadata.httpStatusCode !== 200) {
    const errorMessage = `Upload failed`;
    throw new Error(errorMessage);
  }
}

async function doesConvertedFileExist(fileKey: string): Promise<boolean> {
  const s3Response = await s3Client.send(
    new ListObjectsV2Command({
      Bucket: OutputBucket,
      MaxKeys: 1,
      Prefix: fileKey,
    })
  );
  if (!s3Response.Contents) {
    if (s3Response.$metadata.httpStatusCode === 200) {
      return false;
    }
    const errorMessage = 'Unknown failure reading from S3';
    throw new Error(errorMessage);
  }

  const fileExists = s3Response.Contents.length === 1;
  return fileExists;
}

function sleep(ms: number): Promise<void> {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function trimExcessSpaces(filename: string): string {
  return filename.trim().replaceAll(/  */g, ' ');
}

export function encodeFilenameForS3(filename: string): string {
  const characterMapping = new Map([
    ['\\', `%5C`],
    ['&', `%26`],
    ['@', `%40`],
    [':', `%3A`],
    [',', `%2C`],
    ['$', `%24`],
    ['=', `%3D`],
    ['+', `%2B`],
    ['?', `%3F`],
    [';', `%3B`],
    [' ', '+'], // Must be after '+' mapping
  ]);

  let newFilename = filename;

  characterMapping.forEach((value, key) => {
    newFilename = newFilename.replaceAll(key, value);
  });

  return newFilename;
}

export function buildS3DownloadUrl(fileKey: string): string {
  const s3EncodedName = encodeFilenameForS3(fileKey);
  return `https://s3.eu-west-1.amazonaws.com/${OutputBucket}/${s3EncodedName}`;
}
