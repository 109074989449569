import { nanoid } from 'nanoid';
import { Typography } from '@mui/material';
import { categoryIconDetails } from './CategoryIconData';

const placeholderIconDetails = {
  names: [''],
  colour: 'hsl(198, 40%, 50%)',
  icon: <g />,
};

interface CategoryIconProps {
  categoryName: string;
  width: string;
}

export function CategoryIcon({
  categoryName,
  width,
}: CategoryIconProps): JSX.Element {
  const plainCategoryName = categoryName.replace(/^_?(.*)/, '$1');
  const isRestricted = categoryName.startsWith('_') || categoryName === 'About';

  const hue = 198;
  const saturation = 40;
  const lightness = 50;

  const iconDetails =
    categoryIconDetails.find(({ names }) =>
      names
        .map((name) => name.toLowerCase())
        .includes(plainCategoryName.toLowerCase())
    ) ?? placeholderIconDetails;

  const foundIcon = iconDetails !== placeholderIconDetails;

  const baseColour =
    iconDetails.colour ?? `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  return (
    <div
      style={{
        position: 'relative',
        width,
        minWidth: width, // Prevent the icon from shrinking
        height: width, // Is square
        lineHeight: 0,
        filter: isRestricted ? 'grayscale(100%)' : undefined,
      }}
    >
      {buildTemplateSvgIcon(baseColour, iconDetails.icon)}
      {!foundIcon && (
        <Typography
          sx={{
            fontSize: '2.7rem',
            color: 'white',
            position: 'absolute',
            inset: 0,
            textAlign: 'center',
            height: 'fit-content',
          }}
        >
          {categoryName.charAt(0)}
        </Typography>
      )}
    </div>
  );
}

function buildTemplateSvgIcon(
  baseColour: string,
  foregroundSvg: JSX.Element
): JSX.Element {
  // Safari needs the clipPath ID to be unique for every SVG on the page.
  // I suspect this is an interpretation of the SVG spec that says every ID must
  // be unique in the node tree.
  const clipPathId = `rounded_corners_${nanoid()}`;
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 252 252"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={1.5}
    >
      <g>
        <clipPath id={clipPathId}>
          <path
            d="M251.105,195.704C251.105,226.175 226.18,251.104 195.717,251.104L55.388,251.104C24.925,251.104 0,226.175 0,195.704L0,55.388C0,24.925 24.925,0 55.388,0L195.717,0C226.18,0 251.105,24.925 251.105,55.388L251.105,195.704Z"
            clipRule="nonzero"
          />
        </clipPath>
        <g clipPath={`url(#${clipPathId})`}>
          <rect x="0" y="0" width="252" height="252" fill={baseColour} />
          {foregroundSvg}
        </g>
      </g>
    </svg>
  );
}
