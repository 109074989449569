import { Box, Typography, Stack, Skeleton, Fade } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { GuidelinesIndexEntry } from '../model/GuidelinesIndex';
import { guidelinePathToUrlParam } from '../services/GuidelinePathToUrlParam';
import { useGuidelinesLoaderProviderContext } from '../services/GuidelinesLoaderProviderContext';
import {
  EmptyGuidelinesProvider,
  GuidelinesAndSource,
  GuidelinesProvider,
} from '../services/GuidelinesProvider';
import { GuidelinesProviderContext } from '../services/GuidelinesProviderContext';
import { getMicroguideAsPageSection } from '../services/MicroguideDataProvider';

interface GuidelineLoaderProps {
  children?: React.ReactNode;
}

export function GuidelineLoader({ children }: GuidelineLoaderProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [guidelines, setGuidelines] = useState<GuidelinesAndSource>();
  const [guidelineFileHandle, setGuidelineFileHandle] =
    useState<FileSystemFileHandle>();
  const [guidelinesPathMap, setGuidelinesPathMap] = useState(
    new Map<string, GuidelinesIndexEntry>()
  );
  const [guidelineCount, setGuidelineCount] = useState(0);
  const [lastModified, setLastModified] = useState(new Date(0));

  const { loadGuidelinesIndex } = useGuidelinesLoaderProviderContext();

  useEffect(() => {
    async function getGuidelinesIndex(): Promise<void> {
      setLoading(true);

      try {
        if (!loadGuidelinesIndex) throw new Error('No guidelines provider');
        const guidelinesIndex = await loadGuidelinesIndex();
        const tempGuidelinesPathMap = buildGuidelinesPathMap(
          guidelinesIndex.indexEntries
        );
        setGuidelinesPathMap(tempGuidelinesPathMap);
        setGuidelineCount(guidelinesIndex.guidelineCount);
        setLastModified(guidelinesIndex.lastModified);
      } catch (innerError) {
        const typedError = innerError as { message: string };
        setError(typedError.message);
      } finally {
        setLoading(false);
      }
    }

    getGuidelinesIndex();
  }, [loadGuidelinesIndex]);

  // Get the path to the guideline from the URL
  const params = useParams();
  const encodedUrl = params.encodedUrl ?? '';
  const guidelineIndexEntry = guidelinesPathMap.get(encodedUrl);
  const pathToGuideline = guidelineIndexEntry?.path ?? '';

  // Get the page ID from the URL parameter
  const [searchParams] = useSearchParams();
  const pageId = searchParams.get('pageId') ?? undefined;
  const singlePageRender = searchParams.get('singlePageRender') === 'true';
  const encodedTargetUrl = searchParams.get('targetUrl') ?? '';
  const targetUrl = encodedTargetUrl
    ? decodeURIComponent(encodedTargetUrl)
    : undefined;

  const guidelinesLoaderProvider = useGuidelinesLoaderProviderContext();
  const { loadGuideline, getUriTransformer, getEndsWithUriTransformer } =
    guidelinesLoaderProvider;

  useEffect(() => {
    async function readGuideline(): Promise<void> {
      setLoading(true);

      if (pathToGuideline.length === 0) {
        return;
      }

      try {
        if (!loadGuideline) throw new Error('No guidelines provider');
        const guidelineAndMetaData = await loadGuideline(pathToGuideline);

        // TODO: Would like a clean-up function to return from this useEffect call
        // Without it we occasionally get these warnings:
        // Warning: Can't perform a React state update on an unmounted
        // component. This is a no-op, but it indicates a memory leak
        // in your application. To fix, cancel all subscriptions and
        // asynchronous tasks in a useEffect cleanup function.
        setGuidelines(guidelineAndMetaData);
        if (guidelineAndMetaData.fileHandle)
          setGuidelineFileHandle(guidelineAndMetaData.fileHandle);
      } catch (getError) {
        setError((getError as { message: string }).message);
      } finally {
        setLoading(false);
      }
    }

    readGuideline();
  }, [loadGuideline, pathToGuideline, setGuidelines]);

  let placeholderElement: React.ReactNode = <div />;

  if (loading) {
    placeholderElement = (
      <Fade in style={{ transitionDelay: '300ms' }}>
        <Stack spacing={2} paddingTop={2} alignItems="center">
          <Skeleton variant="rectangular" width="100%" height={64} />
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Stack>
      </Fade>
    );
  }

  if (error) {
    placeholderElement = <Typography>{error}</Typography>;
  }

  const guidelinesProvider: GuidelinesProvider = useMemo(() => {
    if (guidelines) {
      return {
        guidelines,
        setGuidelines,
        uriTransformer: getUriTransformer(pathToGuideline),
        endsWithUriTransformer: getEndsWithUriTransformer?.(pathToGuideline),
        getTargetUrlSection: getMicroguideAsPageSection,
        fileHandle: guidelineFileHandle,
        pageId,
        singlePageRender,
        metaData: guidelines.pages.metaData,
        textReplacements: new Map<string, string>([
          ['GUIDELINE_COUNT', guidelineCount.toString()],
          ['LAST_MODIFIED', lastModified.toLocaleDateString() ?? ''],
        ]),
        targetUrl,
      };
    }

    return EmptyGuidelinesProvider;
  }, [
    getUriTransformer,
    getEndsWithUriTransformer,
    guidelineFileHandle,
    guidelines,
    pageId,
    pathToGuideline,
    singlePageRender,
    guidelineCount,
    lastModified,
    targetUrl,
  ]);

  const isGuidelineReady = !loading && !error && guidelines !== undefined;

  return (
    <GuidelinesProviderContext.Provider value={guidelinesProvider}>
      <Box hidden={isGuidelineReady} id="guideline-loader-placeholder">
        {placeholderElement}
      </Box>
      <div
        hidden={!isGuidelineReady}
        id="guideline-loader-ready-element"
        data-cy="guideline_loaded"
      >
        {children}
      </div>
    </GuidelinesProviderContext.Provider>
  );
}

function buildGuidelinesPathMap(indexEntries: GuidelinesIndexEntry[]) {
  const entriesByUrl = new Map<string, GuidelinesIndexEntry>();
  indexEntries.forEach((guidelineEntry) => {
    const urlParam = guidelinePathToUrlParam(guidelineEntry.path);
    entriesByUrl.set(urlParam, guidelineEntry);

    // Sometimes the URL encoding battles with the browser.
    // For instance '–' (endash, not a hyphen) is URL encoded
    // to '%E2%80%93' but the browser converts it back to '–'
    // and passes that in the URL. So we need map both the URL
    // encoded path and one without URL encoding.
    const backupUrlParam = decodeURIComponent(urlParam);
    entriesByUrl.set(backupUrlParam, guidelineEntry);
  });

  return entriesByUrl;
}
