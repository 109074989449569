import matter from 'gray-matter';
import {
  GuidelinePages,
  GuidelinePage,
  GuidelineSection,
  MenuItem,
} from '../model/GuidelinePage';
import { getHeaderDetails } from './HeaderParser';
import { obsidianImageToHtmlImg } from './ObsidianMarkdownParser';

// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer;

export function markdownToPages(markdownText: string): GuidelinePages {
  const frontMatter = matter(markdownText);
  const metaData = new Map<string, string>();
  Object.entries(frontMatter.data).forEach(([key, value]) => {
    metaData.set(key, value.toString());
  });
  const allPages = markdownToPage(frontMatter.content);
  const pagesById = buildGuidelinesMap(allPages);
  return { rootPage: allPages[0], allPagesById: pagesById, metaData };
}
export function markdownToPage(markdownText: string): GuidelinePage[] {
  // if (!markdownText) {
  //   return [];
  // }

  let currentPage: GuidelinePage = {
    id: '',
    title: '',
    subheading: '',
    sections: [{ content: '', id: '0' }],
  };

  let isInSubPage = false;
  const pagesStack = [currentPage];
  const allPages = [currentPage];

  // Remove HTML comments
  const cleanedText = markdownText.replace(/<!--[\s\S]*?-->/g, '');

  const allLines = cleanedText.split(/\n/);

  allLines.forEach((line, index) => {
    try {
      const isStartingSubMenu = line.startsWith('>>') || line.startsWith('!>>');
      const isEndingSubMenu = line.startsWith('<<') || line.startsWith('!<<');
      const isHeadingOne = line.startsWith('#') && !line.startsWith('##');
      const isHeadingTwo = line.startsWith('##') && !line.startsWith('###');

      if (isStartingSubMenu) {
        const headerDetails = getHeaderDetails(line);

        // Build the submenu definition
        const subMenuItem: MenuItem = {
          title: headerDetails.title,
          subtitle: headerDetails.subtitleText,
          color: headerDetails.colorHexValue,
          targetType: headerDetails.targetType ?? 'page',
          // The targetKey needs to match the pageId below
          targetKey: `${currentPage.id}/${
            currentPage.sections[currentPage.sections.length - 1].id
          }-${pageTitleToPathComponent(headerDetails.title)}`,
          targetUrl: headerDetails.targetUrl,
        };

        // Add the submenu to the last section of the last page
        const pageSections = pagesStack[pagesStack.length - 1].sections;
        if (pageSections[pageSections.length - 1].menuItems !== undefined) {
          pageSections[pageSections.length - 1].menuItems?.push(subMenuItem);
        } else {
          pageSections[pageSections.length - 1].menuItems = [subMenuItem];
        }

        // If the submenu is to a new page then build the outline of it and add it to the stack
        const isNewSubPage =
          subMenuItem.targetType === 'page' ||
          subMenuItem.targetType === 'microguide';
        if (isNewSubPage) {
          const newPage: GuidelinePage = {
            // Needs to match the targetKey above
            id: `${currentPage.id}/${
              currentPage.sections[currentPage.sections.length - 1].id
            }-${pageTitleToPathComponent(headerDetails.title)}`,
            title: headerDetails.title,
            subheading: headerDetails.subtitleText,
            sections: [
              { content: '', id: '0', targetUrl: headerDetails.targetUrl },
            ],
          };
          pagesStack.push(newPage);
          allPages.push(newPage);
          currentPage = pagesStack[pagesStack.length - 1];
        }
        isInSubPage = isNewSubPage;
      } else if (isEndingSubMenu) {
        if (isInSubPage) {
          pagesStack.pop();
          currentPage = pagesStack[pagesStack.length - 1];
        }
        isInSubPage = true;
      } else if (isHeadingOne) {
        const headerDetails = getHeaderDetails(line);
        if (headerDetails.title.trim() !== '') {
          currentPage.title = headerDetails.title;
        }
        if (headerDetails.subtitleText?.trim() !== '') {
          currentPage.subheading = headerDetails.subtitleText;
        }

        const isFirstPage = pagesStack.length === 1;
        if (isFirstPage) {
          currentPage.id = pageTitleToPathComponent(currentPage.title);
        }
      } else if (isHeadingTwo) {
        const headerDetails = getHeaderDetails(line);
        const newSection: GuidelineSection = {
          content: '',
          color: headerDetails.colorHexValue,
          footer: headerDetails.subtitleText,
          targetUrl: headerDetails.targetUrl,
        };

        if (headerDetails.title.trim() !== '') {
          newSection.header = headerDetails.title;
        }

        // Remove current section if it is empty
        const currentSection =
          currentPage.sections[currentPage.sections.length - 1];
        const hasContent = currentSection.content.trim().length > 0;
        const hasSubMenus =
          currentSection.menuItems && currentSection.menuItems.length > 0;
        const hasTargetUrl =
          currentSection.targetUrl && currentSection.targetUrl.length > 0;
        if (!(hasContent || hasSubMenus || hasTargetUrl)) {
          currentPage.sections.pop();
        }

        newSection.id = currentPage.sections.length.toString();
        currentPage.sections.push(newSection);
      } else {
        const lastSection =
          currentPage.sections[currentPage.sections.length - 1];

        if (!lastSection.content) {
          lastSection.content = line;
        } else {
          lastSection.content += line;
        }
        if (lastSection.content !== '') {
          lastSection.content += '\n';
        }
      }
    } catch (error) {
      throw new Error(`Error parsing line ${index}: ${error}`);
    }
  });

  // Convert Obsidian image to HTML images
  postProcessContent(allPages, obsidianImageToHtmlImg);

  // Replace double escaped > and < characters with the real thing
  postProcessContent(allPages, (content) =>
    content.replace(/\\&lt;/gi, '<').replace(/\\&gt;/gi, '>')
  );

  // Trim white space
  postProcessContent(allPages, (content) => {
    const trimmedContent = content.trim();
    const isEmptyContent = trimmedContent.length === 0;
    return isEmptyContent ? '' : `${trimmedContent}\n`;
  });

  return allPages;
}

export function buildGuidelinesMap(
  guidelines: GuidelinePage[]
): Map<string, GuidelinePage> {
  const guidelinesMap = new Map<string, GuidelinePage>();
  guidelines.forEach((guidelinePage) => {
    guidelinesMap.set(guidelinePage.id, guidelinePage);
  });

  return guidelinesMap;
}

export function pageTitleToPathComponent(pageTitle: string): string {
  return pageTitle.replace(/\//g, '_2F');
}

function postProcessContent(
  allPages: GuidelinePage[],
  strategy: (content: string) => string
) {
  allPages.forEach((page) => {
    page.sections.forEach((section) => {
      // eslint-disable-next-line no-param-reassign
      section.content = strategy(section.content);
    });
  });
}
