import { ReactNode, useEffect, useState } from 'react';
import { useSpring, config, animated, to } from '@react-spring/web';
import { useSwipeable } from 'react-swipeable';
import { Device } from '@capacitor/device';

interface PullToRefreshProps {
  onRefresh: () => Promise<void>;
  children: ReactNode;
}

export function PullToRefresh({ onRefresh, children }: PullToRefreshProps) {
  const thresholdHeight = 68;
  const thresholdLimit = 136;
  const initialValues = {
    y: 0,
    dragProportion: 0,
    rotation: 0,
  };
  const [springs, api] = useSpring(() => ({
    from: initialValues,
  }));
  const [isInPullDownState, setIsInPullDownState] = useState(false);

  // Find out if we running as a web app or a native app.
  const [devicePlatform, setDevicePlatform] = useState('web');
  useEffect(() => {
    const populateDevicePlatform = async () => {
      const info = await Device.getInfo();

      setDevicePlatform(info.platform);
    };

    populateDevicePlatform();
  }, []);

  const onSwipingHandlers = useSwipeable({
    onSwipeStart: (eventData) => {
      // Only start the pull down if we're running in a mobile app
      // and the window is scrolled to the top and the user is swiping down.
      const newIsInPullDownState =
        devicePlatform !== 'web' &&
        window.scrollY === 0 &&
        eventData.dir === 'Down';
      setIsInPullDownState(newIsInPullDownState);

      if (newIsInPullDownState) eventData.event.preventDefault();
    },
    onSwiping: (eventData) => {
      // Ignore if we aren't pulling down.
      if (!isInPullDownState) return;

      // Prevent the page from scrolling.
      eventData.event.preventDefault();

      // Adjust all the spring values as the user swipes down:
      // y position, opacity, and rotation.
      const movementY = eventData.absY;
      const dragProportion = movementY / thresholdHeight;
      const newY = calculateY(movementY, thresholdLimit);
      const rotation = (newY / thresholdLimit) * 360;
      api.start({
        y: newY,
        dragProportion: dragProportion > 1 ? 1 : dragProportion,
        rotation,
        immediate: true,
      });
    },
    onSwipedDown: (eventData) => {
      // Ignore if not for us.
      if (!isInPullDownState) return;

      // Reset the state, so we don't accidentally swallow scroll events.
      setIsInPullDownState(false);

      const movementY = eventData.absY;

      const isPastThreshold = movementY > thresholdHeight;
      if (isPastThreshold) {
        api.start({
          to: [
            {
              y: thresholdHeight,
              dragProportion: 1,
              rotation: 0,
              config: config.gentle,
            },
            {
              y: thresholdHeight,
              dragProportion: 1,
              rotation: 360,
              config: config.slow,
            },
            { ...initialValues },
          ],
          immediate: false,
        });
        onRefresh();
      } else {
        api.start({
          ...initialValues,
          immediate: false,
          config: config.stiff,
        });
      }
    },
    touchEventOptions: { passive: false },
  });

  return (
    <div>
      <div
        // This div is used to clip the refresh icon so it doesn't display over the toolbar.
        className="clip-wrapper relative w-full"
        style={{
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <animated.div
          className="refresh-icon absolute w-full flex justify-center z-10"
          style={{
            transform: to(springs.rotation, (value) => `rotate(${value}deg)`),
            y: to(springs.y, (value) => `calc(${value - 40}px - 1em)`),
          }}
        >
          <div
            className="rounded-full flex items-center justify-center p-2 bg-white"
            style={{
              boxShadow:
                '0 0 6px -1px rgb(0 0 0 / 0.2), 0 0 4px -2px rgb(0 0 0 / 0.2)',
            }}
          >
            <animated.svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              style={{
                fill: to(
                  springs.dragProportion,
                  (value) => (value < 1 ? '#9ca3af' : '#65a30d') // fill-grey-400 : fill-lime-600
                ),
              }}
              className="w-6 h-6"
            >
              <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
            </animated.svg>
          </div>
        </animated.div>
        <div {...onSwipingHandlers}>{children}</div>
      </div>
    </div>
  );
}

function calculateY(movementY: number, thresholdLimit: number): number {
  const lowerLimit = 0.8 * thresholdLimit;
  const upperLimit = 1.4 * thresholdLimit;

  let y: number;

  if (movementY <= lowerLimit) {
    y = movementY;
  } else if (movementY > lowerLimit && movementY <= upperLimit) {
    const progress = (movementY - lowerLimit) / (upperLimit - lowerLimit);
    y =
      lowerLimit +
      ((thresholdLimit - lowerLimit) * (1 - Math.cos(Math.PI * progress))) / 2;
  } else {
    y = thresholdLimit;
  }

  return y;
}
