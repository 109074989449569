import { Readable } from 'node:stream';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Network } from '@capacitor/network';
import { GuidelinePage } from '../model/GuidelinePage';
import {
  GuidelinesIndex,
  GuidelinesIndexEntry,
  GuidelinesIndexEntryJsonSchema,
  GuidelinesIndexJsonEntry,
  GuidelinesIndexJsonSchema,
} from '../model/GuidelinesIndex';
import { buildGuidelinesMap } from '../guideline_parser/GuidelineParser';
import { GuidelinesAndSource } from './GuidelinesProvider';

// Set the AWS Region.
const REGION = 'eu-west-1';
// Create an Amazon S3 service client object.
const s3Client = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: REGION }),
    identityPoolId: 'eu-west-1:7fbc0643-369d-4cb1-9d51-7ddcb95ff4d5',
  }),
});

const moduleRegEx = /[^/]*\/(.*)\/(.*)\.json/;
const keyRegEx = /[^/]*\/(.*)\.json/;

export async function loadGuidelinesIndexFromS3(
  srcBucket: string,
  indexKey: string,
  showAbout = false
): Promise<GuidelinesIndex> {
  const indexFile = await loadJsonFileFromS3(srcBucket, indexKey);

  return buildGuidelinesIndexFromJson(indexFile, showAbout);
}

export function buildGuidelinesIndexFromJson(
  indexFile: JSONFileFromS3,
  showAbout: boolean
) {
  const indexEntries: GuidelinesIndexJsonEntry[] = [];
  // Parse one entry at a time from the index file in case of errors
  (indexFile.contents as unknown[]).forEach(
    (indexEntryJson: unknown, index: number) => {
      try {
        const indexEntry = GuidelinesIndexEntryJsonSchema.parse(indexEntryJson);
        indexEntries.push(indexEntry);
      } catch (error) {
        console.error(`Error at index ${index}:`, error);
      }
    }
  );

  const cleanAndPopulatedIndexEntries =
    populateModuleAndGuidelineName(indexEntries);

  const indexEntriesForCount = showAbout
    ? cleanAndPopulatedIndexEntries
    : cleanAndPopulatedIndexEntries.filter(
        ({ path }) => !path.startsWith('About/')
      );

  return {
    indexEntries: cleanAndPopulatedIndexEntries,
    lastModified: indexFile.lastModified,
    guidelineCount: indexEntriesForCount.length,
  };
}

function populateModuleAndGuidelineName(
  rawIndexEntries: GuidelinesIndexJsonEntry[]
): GuidelinesIndexEntry[] {
  const populatedIndexEntries = rawIndexEntries.map((indexEntry) => {
    const { moduleName, guidelineName, guidelinePath } = decodeKey(
      indexEntry.key
    );
    if (moduleName !== '') {
      const metadataMap = new Map<string, string | string[]>();
      if (indexEntry.metadata_v2) {
        Object.entries(indexEntry.metadata_v2).forEach(([key, value]) => {
          metadataMap.set(key, value);
        });
      }

      return {
        key: indexEntry.key,
        md5: indexEntry.md5,
        module: moduleName,
        guidelineName,
        path: guidelinePath,
        metadata: metadataMap,
      } as GuidelinesIndexEntry;
    }
    return undefined;
  });

  const cleanAndPopulatedIndexEntries = populatedIndexEntries.filter(
    (item) => item
  ) as GuidelinesIndexEntry[];

  return cleanAndPopulatedIndexEntries;
}

function decodeKey(key: string): {
  moduleName: string;
  guidelineName: string;
  guidelinePath: string;
} {
  const [, moduleName, guidelineName] = key.match(moduleRegEx) ?? ['', '', ''];
  const [, guidelinePath] = key.match(keyRegEx) ?? ['', ''];
  return { moduleName, guidelineName, guidelinePath };
}

export async function loadGuidelineFromS3(
  srcBucket: string,
  rootPath: string,
  guidelineKey: string
): Promise<GuidelinesAndSource> {
  const srcKey = `${rootPath}/${guidelineKey}.json`;
  let pagesToReturn: GuidelinePage[] = [];

  const haveBrowserInternetConnection = navigator.onLine;
  const haveMobileInternetConnection = (await Network.getStatus()).connected;
  if (!haveBrowserInternetConnection || !haveMobileInternetConnection) {
    const errorPage = {
      id: '',
      title: 'No internet connection',
      sections: [
        {
          content:
            "You don't seem to be connected to the internet. Please check your connection and try again.",
          items: [],
        },
      ],
    };

    pagesToReturn = [errorPage];
  } else {
    try {
      const legacyMenuItemProcessor = (text: string) => {
        // Only transform the text if it contains the legacy format
        if (text.includes('"targetKey":')) {
          return text;
        }
        return text.replace(/"target":/gm, '"targetUrl":');
      };
      const jsonGuidelines = await loadJsonFileFromS3(
        srcBucket,
        srcKey,
        legacyMenuItemProcessor
      );
      // TODO: manage there being meta data as well as the guidelines
      // Probably find a way to handle the json being a version of GuidelinesAndSource
      pagesToReturn = jsonGuidelines.contents as GuidelinePage[];
    } catch (error) {
      const errorMessage = (error as { message: string }).message;
      const errorPage = {
        id: '',
        title: `Error loading guideline`,
        sections: [
          {
            content: `${errorMessage}\n\nReceived reading '${srcKey}'`,
            items: [],
          },
        ],
      };

      pagesToReturn = [errorPage];
    }
  }

  // TODO: add the metadata read from the JSON
  const metaData = new Map<string, string>([
    ['s3Bucket', srcBucket],
    ['s3Key', srcKey],
  ]);

  const pagesById = buildGuidelinesMap(pagesToReturn);
  const guideline = {
    rootPage: pagesToReturn[0],
    allPagesById: pagesById,
    metaData,
  };

  return { pages: guideline, key: guidelineKey };
}

type JSONFileFromS3 = {
  contents: unknown;
  lastModified: Date;
};

async function loadJsonFileFromS3(
  bucket: string,
  key: string,
  textProcessor: (text: string) => string = (text) => text
) {
  if (!navigator.onLine) {
    throw new Error('No internet connection');
  }

  const s3Response = await s3Client.send(
    new GetObjectCommand({
      Bucket: bucket,
      Key: key,
      ResponseCacheControl: 'no-cache', // Still caches, but always checks it has the latest
    })
  );
  if (!s3Response.Body) {
    const errorMessage = `${key} returned undefined`;
    throw new Error(errorMessage);
  }

  const lastModified = s3Response.LastModified ?? new Date(0);

  const fileContents = await s3ResponseBodyToString(s3Response.Body);
  const fileContentsProcessed = textProcessor(fileContents);
  const contentsAsJson = JSON.parse(fileContentsProcessed);
  return { contents: contentsAsJson, lastModified } as JSONFileFromS3;
}

function isReadableStream(
  body: ReadableStream | Readable | Blob
): body is ReadableStream {
  return (body as ReadableStream).getReader !== undefined;
}

function isReadable(body: ReadableStream | Readable | Blob): body is Readable {
  return (body as Readable).read !== undefined;
}

async function s3ResponseBodyToString(
  body: ReadableStream | Readable | Blob
): Promise<string> {
  if (isReadableStream(body)) return readableStreamToString(body);
  if (isReadable(body)) return readableToString(body);
  return body.text();
}

async function readableStreamToString(stream: ReadableStream): Promise<string> {
  const chunks: Uint8Array[] = [];
  const reader = stream.getReader();

  if (!stream.getReader) {
    throw new Error('Not a ReadableStream');
  }

  let isMoreData = true;
  do {
    // eslint-disable-next-line no-await-in-loop
    const { done, value } = await reader.read();
    if (done) {
      isMoreData = false;
    } else {
      chunks.push(value as Uint8Array);
    }
  } while (isMoreData);

  return DecodeUint8Array(concat(chunks));
}

async function readableToString(reader: Readable): Promise<string> {
  const chunks: Buffer[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for await (const chunk of reader) {
    chunks.push(chunk as Buffer);
  }

  const allData = Buffer.concat(chunks);
  return allData.toString('utf-8');
}

function concat(arrays: Uint8Array[]): Uint8Array {
  // sum of individual array lengths
  const totalLength = arrays.reduce((acc, value) => acc + value.length, 0);

  if (!arrays.length) return new Uint8Array();

  const result = new Uint8Array(totalLength);

  // for each array - copy it over result
  // next array is copied right after the previous one
  let length = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const array of arrays) {
    result.set(array, length);
    length += array.length;
  }

  return result;
}

/**
 * Convert an Uint8Array into a string.
 *
 * @returns {String}
 */
function DecodeUint8Array(array: Uint8Array): string {
  return new TextDecoder('utf-8').decode(array);
}
