import './SectionContent.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import deepmerge from 'deepmerge';
import { rehype } from 'rehype';
import { useCallback } from 'react';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';

type SectionContentProps = {
  content: string;
  imgErrorHandler: (uri: string) => void;
  openLinksInNewTab?: boolean;
};

const schema = deepmerge(defaultSchema, {
  tagNames: ['style'],
  attributes: { '*': ['style', 'className'] },
});

export function SectionContent({
  content,
  imgErrorHandler,
  openLinksInNewTab = false,
}: SectionContentProps): JSX.Element {
  const { uriTransformer } = useGuidelinesProviderContext();

  const sanitisedMarkdown = rehype()
    .data('settings', { fragment: true })
    .use(rehypeSanitize, schema)
    .processSync(content)
    .toString();

  // Create a builder for img tags that adds an error handler
  const imgBuilder = useCallback(
    (
      props: React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
      >
    ) => (
      <img
        {...props}
        onError={(event) => {
          const currentUrl = window.location.href;
          const lastSlashIndex = currentUrl.lastIndexOf('/');
          const baseUrl = currentUrl.substring(0, lastSlashIndex + 1);

          const relativeURI = event.currentTarget.src.replace(baseUrl, '');
          imgErrorHandler(relativeURI);
        }}
      />
    ),
    [imgErrorHandler]
  );

  const aBuilder = useCallback(
    (
      props: React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
      >
    ) => <a {...props} rel="noopener noreferrer" />,
    []
  );
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      transformImageUri={uriTransformer}
      components={{ img: imgBuilder, a: aBuilder }}
      linkTarget={openLinksInNewTab ? '_blank' : ''}
    >
      {sanitisedMarkdown}
    </ReactMarkdown>
  );
}
