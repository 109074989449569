import { GuidelinesIndexEntry } from '../model/GuidelinesIndex';

type CategoryKeyType = 'Category' | 'CategoryAndSubfolder';

export function categorizeGuidelines(
  guidelinesIndex: GuidelinesIndexEntry[],
  showAbout: boolean,
  keyType: CategoryKeyType = 'Category'
) {
  const tempGuidelineEntriesByCategory = new Map<
    string,
    GuidelinesIndexEntry[]
  >();
  guidelinesIndex.forEach((guidelineEntry) => {
    const moduleName = guidelineEntry.module;
    if (!showAbout && moduleName === 'About') {
      return;
    }

    const { category, subfolder } = getCategoryAndSubfolder(guidelineEntry);

    const hasSubfolder = subfolder.length > 0;
    const useSubfolderInKey =
      keyType === 'CategoryAndSubfolder' && hasSubfolder;
    const key = useSubfolderInKey ? `${category}—${subfolder}` : `${category}`;

    const guidelinesInCategory = tempGuidelineEntriesByCategory.get(key) ?? [];
    guidelinesInCategory.push(guidelineEntry);
    tempGuidelineEntriesByCategory.set(key, guidelinesInCategory);
  });

  const sortedByCategoryName = new Map(
    [...tempGuidelineEntriesByCategory.entries()].sort((a, b) => {
      const categoryA = a[0].toLocaleLowerCase();
      const categoryB = b[0].toLocaleLowerCase();
      return categoryA.localeCompare(categoryB);
    })
  );
  return sortedByCategoryName;
}

export function buildGuidelinesByFolder(
  guidelinesIndex: GuidelinesIndexEntry[]
) {
  const tempGuidelineEntriesByFolder = new Map<
    string,
    GuidelinesIndexEntry[]
  >();
  guidelinesIndex.forEach((guidelineEntry) => {
    const { subfolder } = getCategoryAndSubfolder(guidelineEntry);

    const guidelinesInFolder =
      tempGuidelineEntriesByFolder.get(subfolder) ?? [];
    guidelinesInFolder.push(guidelineEntry);
    tempGuidelineEntriesByFolder.set(subfolder, guidelinesInFolder);
  });
  return sortGuidelineEntries(tempGuidelineEntriesByFolder);
}

function sortGuidelineEntries(
  entriesByFolder: Map<string, GuidelinesIndexEntry[]>
) {
  entriesByFolder.forEach((indexEntries) =>
    indexEntries.sort((a, b) => {
      const guidelineNameA = a.guidelineName.toLocaleLowerCase();
      const guidelineNameB = b.guidelineName.toLocaleLowerCase();
      return guidelineNameA.localeCompare(guidelineNameB);
    })
  );
  return new Map(
    [...entriesByFolder.entries()].sort((a, b) => {
      const folderNameA = a[0].toLocaleLowerCase();
      const folderNameB = b[0].toLocaleLowerCase();
      return folderNameA.localeCompare(folderNameB);
    })
  );
}

export interface CategoryAndSubfolder {
  category: string;
  subfolder: string;
}

export function getCategoryAndSubfolder(
  guidelineEntry: GuidelinesIndexEntry
): CategoryAndSubfolder {
  // The categoryName is the parent folder of the moduleName
  const categoryName = guidelineEntry.module.split('/')[0];

  let folderName = '';
  const isInFolder = guidelineEntry.module.includes('/');
  if (isInFolder) {
    const pathSeparatorIndex = guidelineEntry.module.indexOf('/');
    folderName = guidelineEntry.module.substring(pathSeparatorIndex + 1);
  }

  return { category: categoryName, subfolder: folderName };
}
