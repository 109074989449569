import {
  findResourceUrlsBySourcePath,
  ResourcePathAndUrl,
} from './FileSystemGuidelinesLoader';

const categoryFromPathRegex = /(.*?)\/.*/;
const relativePathFromPathRegex = /(.*)\/.*/;

export function buildUriTransformerFactory(baseUrl: string) {
  /**
   * Translates relative URIs to absolute on the guidelines server.
   * Supplied to ReactMarkdown as transformImageUri.
   */
  return function getUriTransformer(
    sourcePath: string
  ): (uri: string) => string {
    // Guard - hit in testing
    if (!(sourcePath && sourcePath.trim())) {
      return (uri: string): string => uri;
    }

    const [, moduleName] = sourcePath.match(categoryFromPathRegex) ?? ['', ''];
    const [, relativePath] = sourcePath.match(relativePathFromPathRegex) ?? [
      '',
      '',
    ];

    return (uri: string): string => {
      if (uri.startsWith('/')) {
        return `${process.env.PUBLIC_URL}${uri}`;
      }

      const needsBaseUrl = !uri.startsWith('http');
      if (needsBaseUrl) {
        const subDir = relativePath.substring(moduleName.length + 1);
        if (uri.startsWith(`${subDir}/`)) {
          return `${baseUrl}${moduleName}/${uri}`;
        }
        return `${baseUrl}${relativePath}/${uri}`;
      }
      return uri;
    };
  };
}

export interface UriTransformerFactory {
  getUriTransformer(sourcePath: string): (uri: string) => string;
  resourcesUrlsBySourcePath: Map<string, ResourcePathAndUrl[]>;
  getEndsWithUriTransformer(sourcePath: string): (uri: string) => string[];
}
export async function buildFileSystemUriTransformerFactory(
  rootPath: FileSystemDirectoryHandle
): Promise<UriTransformerFactory> {
  const resourcesUrlsBySourcePath = await findResourceUrlsBySourcePath(
    rootPath
  );

  const getUriTransformer = (sourcePath: string) => {
    // Guard - hit in testing
    if (!(sourcePath && sourcePath.trim())) {
      return (uri: string): string => uri;
    }

    const [, moduleName] = sourcePath.match(categoryFromPathRegex) ?? ['', ''];
    const [, relativePath] = sourcePath.match(relativePathFromPathRegex) ?? [
      '',
      '',
    ];

    return (uri: string): string => {
      const needsBaseUrl = !uri.startsWith('http');

      if (needsBaseUrl) {
        const resources = resourcesUrlsBySourcePath.get(moduleName);
        if (resources) {
          const cleanUri = uri.startsWith('./') ? uri.substring(2) : uri;
          const subDir = relativePath.substring(moduleName.length + 1);
          const pathToLookFor = (
            uri.startsWith(subDir)
              ? `${moduleName}/${cleanUri}`
              : `${relativePath}/${cleanUri}`
          ).replace(`${moduleName}/`, '');
          const resource = resources.find((resourcePathAndUrl) =>
            resourcePathAndUrl.path.endsWith(pathToLookFor)
          );
          if (resource) {
            return resource.url;
          }
        }
      }
      return uri;
    };
  };

  const getEndsWithUriTransformer = (sourcePath: string) => {
    // Guard - hit in testing
    if (!(sourcePath && sourcePath.trim())) {
      return () => <string[]>[];
    }

    const [, moduleName] = sourcePath.match(categoryFromPathRegex) ?? ['', ''];

    return (uri: string) => {
      const needsBaseUrl = !uri.startsWith('http');

      if (needsBaseUrl) {
        const resources = resourcesUrlsBySourcePath.get(moduleName);
        if (resources) {
          const resourceName = uri.split('/').pop() ?? uri;
          const possibleResources = resources.filter((resourcePathAndUrl) =>
            resourcePathAndUrl.path.endsWith(resourceName)
          );
          if (possibleResources.length > 0) {
            const possiblePaths = possibleResources.map(
              (resourcePathAndUrl) => {
                const pathElements = resourcePathAndUrl.path.split('/');
                const pathElementsWithoutRoot = pathElements.slice(1);
                return pathElementsWithoutRoot.join('/');
              }
            );
            return possiblePaths;
          }
        }
      }
      return <string[]>[];
    };
  };

  return {
    getUriTransformer,
    resourcesUrlsBySourcePath,
    getEndsWithUriTransformer,
  };
}
