import { Device } from '@capacitor/device';
import { useContext, useEffect, useRef, useState } from 'react';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';
import { GuidelinesAppBar } from '../GuidelinesAppBar';
import { ThemeAndTitleContext } from '../ThemeAndTitleProvider';
import { NavigationBreadcrumbs } from './NavigationBreadcrumbs';
import { Page } from './Page';
import { PageHeading } from './PageHeading';
import { useReactToPrint } from 'react-to-print';

export function Guideline(): JSX.Element {
  const {
    guidelines,
    pageId,
    singlePageRender,
    metaData,
    textReplacements,
    targetUrl,
    getTargetUrlSection,
  } = useGuidelinesProviderContext();
  const { setThemeFromName, removeSiteSpecifierFromName, appTitle } =
    useContext(ThemeAndTitleContext);
  const [devicePlatform, setDevicePlatform] = useState('');
  const printComponentRef = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: guidelines.pages.rootPage.title,
    content: () => printComponentRef.current,
    fonts: [
      {
        family: 'Roboto',
        source: 'https://fonts.googleapis.com/css2?family=Roboto',
        weight: '400',
      },
    ],
    // print: async (printIframe: HTMLIFrameElement) => {
    //   // Do whatever you want here, including asynchronous work
    //   await generateAndSavePDF(printIframe);
    // },
  });

  useEffect(() => {
    const populateDevicePlatform = async () => {
      const info = await Device.getInfo();

      setDevicePlatform(info.platform);
    };

    populateDevicePlatform();
  }, []);

  const isEmptyGuideline = guidelines.pages.allPagesById.size === 0;
  if (isEmptyGuideline) {
    return <div />;
  }

  // If no page ID is passed then use the root page
  const pageIdToUse = pageId ?? guidelines.pages.rootPage.id;

  let guidelinePage = guidelines.pages.allPagesById.get(pageIdToUse);

  if (!guidelinePage) {
    return <div />;
  }

  if (targetUrl) {
    const microguideSection = getTargetUrlSection(targetUrl);
    guidelinePage = {
      ...guidelinePage,
      subheading: microguideSection.footer,
      id: 'microguide',
      sections: [{ ...microguideSection, footer: undefined }],
    };
  }

  setThemeFromName(guidelines.pages.rootPage.title);
  const pageTitle = removeSiteSpecifierFromName(guidelinePage.title);

  const isOnMobileApp = devicePlatform !== 'web';

  return (
    <div ref={printComponentRef}>
      <div className="print:hidden">
        <GuidelinesAppBar
          appTitle={appTitle}
          showNav={isOnMobileApp}
          onPrint={handlePrint}
        />
      </div>
      <NavigationBreadcrumbs />
      <div className="print:p-6">
        <PageHeading
          heading={pageTitle}
          subheading={guidelinePage.subheading}
          onClick={() => {
            /* no-op */
          }}
        />
        <Page
          guideline={guidelinePage}
          allPages={guidelines.pages.allPagesById}
          singlePageRender={singlePageRender}
          metaData={metaData}
          textReplacements={textReplacements}
        />
      </div>
    </div>
  );
}
