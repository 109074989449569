import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

export function Guidelines(): JSX.Element {
  return (
    <Container maxWidth="md" className="sm:pl-4 sm:pr-4 pl-0 pr-0">
      <Outlet />
    </Container>
  );
}
