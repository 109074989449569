import { useCallback } from 'react';

type PageHeadingProps = {
  heading: string;
  subheading?: string;
  showEmptySubHeading?: boolean;
  onClick?: () => void;
};

export function PageHeading({
  heading,
  subheading,
  showEmptySubHeading,
  onClick,
}: PageHeadingProps): JSX.Element {
  const reactOnClick = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);
  const showSubHeading =
    showEmptySubHeading || (subheading && subheading.trim().length > 0);
  return (
    <div className="mb-2 flex w-full flex-col">
      <div className="flex w-full flex-grow flex-row space-x-0 bg-[#198f77] text-white">
        <div
          onClick={() => reactOnClick()}
          onKeyDown={(event) => {
            // It's a good practice to check for the "Enter" key for accessibility reasons
            if (event.key === 'Enter') {
              reactOnClick();
            }
          }}
          role="textbox"
          tabIndex={0}
          className="flex-grow p-2 text-center text-2xl/8 font-normal text-inherit"
          data-cy="title"
        >
          {heading}
        </div>
      </div>
      {showSubHeading && (
        <div
          className="bg-neutral-300 py-2 pb-1 text-center text-sm/6 font-medium"
          onClick={() => reactOnClick()}
          onKeyDown={(event) => {
            // It's a good practice to check for the "Enter" key for accessibility reasons
            if (event.key === 'Enter') {
              reactOnClick();
            }
          }}
          role="textbox"
          tabIndex={0}
        >
          {subheading}
        </div>
      )}
    </div>
  );
}
