import { buildUriTransformerFactory } from './UriTransformerFactory';
import { GuidelinesLoaderProvider } from './GuidelinesLoaderProvider';
import {
  loadGuidelineFromS3,
  loadGuidelinesIndexFromS3,
} from './s3GuidelinesLoader';
import { GuidelinesIndex } from '../model/GuidelinesIndex';

export function buildS3GuidelinesProvider(
  bucketName: string,
  rootPath: string,
  indexKey = 'index.json'
): GuidelinesLoaderProvider {
  // Regex to match 'public' for '/public/', '/public/', 'public/', and 'public'.
  const cleanPathRegex = /\/?(.*[^/])\/?/;
  const cleanRootPath = rootPath.replace(cleanPathRegex, '$1');
  const resourcesBucket = bucketName.replace('-json', '');
  const resourcesUrl = `https://s3.eu-west-1.amazonaws.com/${resourcesBucket}/${cleanRootPath}/`;
  const staticAssetsUrl = `https://s3.eu-west-1.amazonaws.com/${resourcesBucket}/${cleanRootPath}/CategoryIcons`;
  const s3UriTransformer = buildUriTransformerFactory(resourcesUrl);
  let guidelinesIndex: GuidelinesIndex;

  const s3GuidelinesProvider: GuidelinesLoaderProvider = {
    loadGuidelinesIndex: async (showAbout = false) => {
      if (!guidelinesIndex) {
        guidelinesIndex = await loadGuidelinesIndexFromS3(
          bucketName,
          indexKey,
          showAbout
        );
      }
      return guidelinesIndex;
    },
    loadGuideline: async (guidelineKey) =>
      loadGuidelineFromS3(bucketName, cleanRootPath, guidelineKey),
    getUriTransformer: s3UriTransformer,
    staticAssetsUrl,
    loadAboveTheFoldGuideline: async () =>
      loadGuidelineFromS3(bucketName, cleanRootPath, 'About/Above The Fold'),
  };

  return s3GuidelinesProvider;
}
