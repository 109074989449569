const regex = /!\[\[([^|\]]*)(\|(\d+)(\D*)(x(\d+))?)?\]\]/gm;
// Visual explanation with examples: https://regex101.com/r/3Skrx0/1

/** RegEx Examples
 *
 * In: "words ![[image.png|100x100]] words"
 * Match: ![[image.png|100x100]]
 * Group 1: image.png
 * Group 2: |100x100
 * Group 3: 100
 * Group 4: null
 * Group 5: x100
 * Group 6: 100
 *
 * In: "words ![[image.png]] words"
 * Match: ![[image.png]]
 * Group 1: image.png
 *
 * In: "words ![[image.png|100%]] words"
 * Match: ![[image.png|100%]]
 * Group 1: image.png
 * Group 2: |100%
 * Group 3: 100
 * Group 4: %
 * */

export function obsidianImageToHtmlImg(obsidianMarkdown: string) {
  const result = obsidianMarkdown.replace(regex, (match, ...args) => {
    const path = args[0] ?? ''; // Group 1
    const hasWidth = Boolean(args[2]);
    const widthDigits = args[2] ?? '100';
    const hasUnits = Boolean(args[3]) && args[3].length > 0;
    const widthUnits = hasUnits ? args[3] : 'px';

    const width = !hasWidth ? '100%' : `${widthDigits}${widthUnits}`;
    return `<img src="${path}" style="width:${width};" />`;
  });

  return result;
}

const imgRegex =
  /<img src=["']([^"']*)["'] style=["']width:(\d*)(\D*);["'] *\/>/gm;

export function htmlImgToObsidianMarkdown(text: string) {
  const result = text.replace(imgRegex, (match, ...args) => {
    const path = args[0];
    const hasWidth = Boolean(args[2]);
    const widthDigits = args[1] ?? '100';
    const hasUnits = Boolean(args[2]) && args[2].length > 0;
    const widthUnits = hasUnits ? args[2] : 'px';

    const widthString = `${widthDigits}${widthUnits}`;
    const isDefaultWidth = widthString === '100%';
    const width = isDefaultWidth || !hasWidth ? '' : `|${widthString}`;
    return `![[${path}${width}]]`;
  });

  return result;
}
