import { Box, Typography } from '@mui/material';

export type WordImportFileDisplayProps = {
  filesToUpload: File[];
};

export function WordImportFileDisplay({
  filesToUpload,
}: WordImportFileDisplayProps): JSX.Element {
  if (filesToUpload.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', color: 'darkslategrey' }}>
        <Typography marginBottom="0.5rem" text-align="center">
          <strong>No file</strong>
        </Typography>
        <Typography>You have not chosen a Word file to upload</Typography>
      </Box>
    );
  }

  const tableRows = filesToUpload.map((file) => (
    <tr key={file.name} id={file.name} style={{ backgroundColor: 'white' }}>
      <td>
        <Typography>
          <strong>{file.name}</strong>
        </Typography>
      </td>
      <td>
        <Typography>{humanFileSize(file.size, true)}</Typography>
      </td>
    </tr>
  ));

  return (
    <table width="100%">
      <thead>{tableRows}</thead>
    </table>
  );
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false): string {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const isMegaBytesOrBigger = bytes > 1024 * 1024;
  const dp = isMegaBytesOrBigger ? 1 : 0;

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  let numberToDisplay = bytes;
  do {
    numberToDisplay /= thresh;
    u += 1;
  } while (
    Math.round(Math.abs(numberToDisplay) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${numberToDisplay.toFixed(dp)} ${units[u]}`;
}
