/* eslint-disable react/style-prop-object */
import { Grid, Slider, Stack, Tooltip, Typography } from '@mui/material';
import { Box, darken } from '@mui/system';
import { useState } from 'react';

interface Hsl {
  hue: number;
  saturation: number;
  lightness: number;
}

export function ColourGrid(): JSX.Element {
  const [selectedColour, setSelectedColour] = useState('');
  return gridElementsByHue(selectedColour, setSelectedColour);
  // const hueIntervals = [
  //   0, 18, 36, 54, 72, 90, 108, 126, 144, 162, 180, 198, 216, 234, 252, 270,
  //   288, 306, 324, 342,
  // ];
  const intervalOffsets = [0, 30, 60];
  const hueIntervals = intervalOffsets.flatMap((angle) => [
    angle,
    angle + 90,
    angle + 180,
    angle + 270,
  ]);
  // const hueIntervals = [100, 332, 200, 0];
  const saturationAndLightnessPairs = [
    [24, 66],
    [76, 56],
    [100, 47],
    [64, 60],
    [85, 62],
    [32, 55],
    [51, 57],
  ];
  // const hueChoices = [
  //   0, 18, 36, 54, 72, 108, 144, 180, 216, 252, 288, 306, 324, 342,
  // ];
  // const hueChoices = [0, 36, 72, 108, 144, 180, 216, 252, 288, 324];
  // const saturationChoices = [15, 40, 70, 100]; // [40, 60, 80, 100];
  const [darkenCoefficient, setDarkenCoefficient] = useState(0.2);
  // const lightnessChoices = [40, 50, 60]; // [40, 50];

  const buildHslValue = (
    hue: number,
    saturation: number,
    lightness: number
  ): Hsl => ({ hue, saturation, lightness });

  const colourCombinations = hueIntervals.flatMap((hueValue) =>
    saturationAndLightnessPairs.flatMap((slPair) =>
      buildHslValue(hueValue, slPair[0], slPair[1])
    )
  );
  // const colourCombinations = hueTriads.flatMap((hueValue) =>
  //   lightnessChoices.flatMap((lightnessValue) =>
  //     saturationChoices.flatMap((saturationValue) =>
  //       buildHslValue(hueValue, saturationValue, lightnessValue)
  //     )
  //   )
  // );

  // const reorderedColourCombinations = new Array<Hsl>(colourCombinations.length);
  // for (let index = 1; index < colourCombinations.length; index += 1) {
  //   const elementIndex = (index * 11) % colourCombinations.length;
  //   reorderedColourCombinations[elementIndex] = colourCombinations[index];
  // }

  const colourElements = colourCombinations.map((hslColour) =>
    buildColourCell(
      hslColour.hue,
      hslColour.saturation,
      hslColour.lightness,
      darkenCoefficient,
      selectedColour,
      setSelectedColour
    )
  );

  const hslStrings = colourCombinations.map(
    (hslValue) =>
      `hsl(${hslValue.hue}, ${hslValue.saturation}%, ${hslValue.lightness}%)\n`
  );

  return (
    <>
      <Grid container spacing={1}>
        {false && (
          <Slider
            defaultValue={0.2}
            valueLabelDisplay="auto"
            step={0.1}
            marks
            min={0}
            max={1}
            onChange={(event, newValue) =>
              setDarkenCoefficient(newValue as number)
            }
          />
        )}
        {colourElements}
      </Grid>
      {hslStrings.map((hslValue, index) => (
        <Typography key={hslValue}>{`${index}: ${hslValue}`}</Typography>
      ))}
    </>
  );
}

function buildColourCell(
  hue: number,
  saturation: number,
  lightness: number,
  darkenCoefficient: number,
  selectedColour: string,
  onSelectColour: (colour: string) => void
): JSX.Element {
  const baseColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  const shadowColor = darken(baseColor, darkenCoefficient);
  const isSelected = selectedColour === baseColor;
  const border = isSelected ? '1px dashed grey' : 'none';
  return (
    <Grid
      item
      key={`${baseColor}-stack`}
      onClick={() => {
        navigator.clipboard.writeText(baseColor);
        onSelectColour(baseColor);
      }}
      sx={{ border }}
    >
      <Tooltip
        title={`${baseColor}\n${shadowColor}`}
        key={`${baseColor}-tooltip`}
      >
        <Box padding="1pt">{buildTemplateSvgIcon(baseColor, shadowColor)}</Box>
      </Tooltip>
    </Grid>
  );
}

function buildTemplateSvgIcon(baseColour: string, shadowColour: string) {
  return (
    <svg
      width="64px"
      height="64px"
      viewBox="0 0 252 252"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="1.5"
      key={baseColour}
    >
      <g transform="matrix(4.16667,0,0,4.16667,-699.446,-99.4459)">
        <path
          d="M228.132,70.836C228.132,78.149 222.15,84.132 214.839,84.132L181.16,84.132C173.849,84.132 167.867,78.149 167.867,70.836L167.867,37.16C167.867,29.849 173.849,23.867 181.16,23.867L214.839,23.867C222.15,23.867 228.132,29.849 228.132,37.16L228.132,70.836Z"
          fill={baseColour}
          fillRule="nonzero"
        />
      </g>
      <clipPath id="_clip1">
        <path
          d="M251.104,195.704C251.104,226.175 226.179,251.104 195.717,251.104L55.387,251.104C24.925,251.104 0,226.175 0,195.704L0,55.387C0,24.925 24.925,-0 55.387,-0L195.717,-0C226.179,-0 251.104,24.925 251.104,55.387L251.104,195.704Z"
          clipRule="nonzero"
        />
      </clipPath>
      <g clipPath="url(#_clip1)">
        <path
          d="M177.315,73.789L254.14,150.614L251.104,150.022L251.104,250.754L147.642,250.754L73.996,177.108L177.315,73.789Z"
          fill={shadowColour}
          stroke={shadowColour}
          strokeWidth="8px"
        />
      </g>
      <g transform="matrix(3.28986,0,0,3.28986,-51.1715,-282.733)">
        <circle
          cx="53.718"
          cy="124.104"
          r="22.256"
          fill={shadowColour}
          stroke="white"
          strokeWidth="2.43px"
        />
      </g>
    </svg>
  );
}

function gridElementsByHue(
  selectedColour: string,
  onSelectColour: (colour: string) => void
) {
  const intervalOffsets = [0, 20, 40];
  // const hueIntervals = intervalOffsets.flatMap((angle) => [
  //   angle,
  //   angle + 60,
  //   angle + 120,
  //   angle + 180,
  //   angle + 240,
  //   angle + 300,
  // ]);
  // const hueIntervals = [100, 332, 200, 0];
  const saturationAndLightnessPairs = [
    [100, 40],
    [40, 50],
    // [24, 66],
    // [76, 56],
    // [100, 47],
    // [64, 60],
    // [85, 62],
    // [32, 55],
    // [51, 57],
  ];

  const hueElements = intervalOffsets.map((hueOffset) => (
    <Stack key={`hueOffset:${hueOffset}`}>
      {saturationAndLightnessPairs.map((slPair) => (
        <Stack direction="row" spacing={1} key={`S${slPair}[0],L${slPair[1]}`}>
          {[0, 60, 120, 180, 240, 300].map((baseHue) =>
            buildColourCell(
              baseHue + hueOffset,
              slPair[0],
              slPair[1],
              0.2,
              selectedColour,
              onSelectColour
            )
          )}
        </Stack>
      ))}
    </Stack>
  ));
  // const hueElements = saturationAndLightnessPairs.map((slPair) => (
  //   <Stack direction="row" spacing={1}>
  //     {hueIntervals.map((hueValue) =>
  //       buildColourCell(
  //         hueValue,
  //         slPair[0],
  //         slPair[1],
  //         0.2,
  //         selectedColour,
  //         onSelectColour
  //       )
  //     )}
  //   </Stack>
  // ));

  return (
    <Grid container spacing={1}>
      {hueElements}
    </Grid>
  );
}
