import { HeaderDetails, TargetType } from './HeaderDetails';

const headerRegex =
  /([#>]+)([^[]*)(\[target: *([^\]]*)\])?(\[colou*r: *([^\]]*)\])?(\[(?:footer:)? *([^\]]*)\])?.*/;
const headerMenuLevelIndex = 1;
const headerMenuNameIndex = 2;
const targetIndex = 3;
const targetNameIndex = 4;
// const colourIndex = 5;
const colourValueIndex = 6;
// const subtitleIndex = 7;
const subtitleTextIndex = 8;

export function getHeaderDetails(headerText: string): HeaderDetails {
  const matchResult = headerText.match(headerRegex);

  if (matchResult == null) {
    return { level: 0, title: '' };
  }

  const headerLevelText = matchResult[headerMenuLevelIndex].trim();
  const headerLevel = headerLevelText.length;

  const headerName = matchResult[headerMenuNameIndex].trim();

  let targetType: TargetType | undefined;
  let targetText: string | undefined;
  let targetUrl: string | undefined;
  const targetMenuItemGroup = matchResult[targetIndex];
  if (targetMenuItemGroup) {
    targetText = matchResult[targetNameIndex];
    targetUrl = targetText;
    const hasProtocol = targetText.includes('://');
    if (hasProtocol) {
      const protocol = targetText
        .substring(0, targetText.indexOf('://'))
        .toLocaleLowerCase();
      switch (protocol) {
        case 'microguide':
          targetType = 'microguide';
          break;

        case 'guideline':
          targetType = 'guideline';
          break;
        default:
          break;
      }
    }

    const hasExtension = targetText.includes('.');
    if (!hasProtocol && hasExtension) {
      const extension = targetText.substring(targetText.lastIndexOf('.'));
      switch (extension) {
        case '.pdf':
          targetType = 'pdf';
          break;
        case '.flowchart':
          targetType = 'flowchart';
          break;

        default:
          break;
      }
    }
  }

  const colorHexValue = matchResult[colourValueIndex]?.trim();
  const subtitleText = matchResult[subtitleTextIndex]
    ?.trim()
    .replace('\\n', '\n');

  return {
    level: headerLevel,
    title: headerName,
    colorHexValue,
    subtitleText,
    targetType,
    targetUrl,
  };
}
