export function applyTextReplacements(
  content: string,
  textReplacements: Map<string, string>
): string {
  let newContent = content;
  textReplacements.forEach((value, key) => {
    const lookUpRegex = new RegExp(`\\%${key}\\%`, 'gim');
    newContent = newContent.replace(lookUpRegex, value);
  });
  return newContent;
}
