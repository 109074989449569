import {
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useState, useCallback } from 'react';
import { SearchGuidelinesInput } from './SearchGuidelinesInput';
import { SearchGuidelinesSearchAndResults } from './SearchGuidelinesSearchAndResults';

interface SearchGuidelinesDialogProps {
  open: boolean;
  handleClose: () => void;
}

export function SearchGuidelinesDialog({
  open,
  handleClose,
}: SearchGuidelinesDialogProps): JSX.Element {
  const [searchText, setSearchText] = useState('');

  const handleCloseWithCleanup = useCallback(() => {
    handleClose();
    setSearchText('');
  }, [handleClose]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleCloseWithCleanup}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          marginTop: 'env(safe-area-inset-top)',
          alignSelf: 'flex-start',
        },
      }}
    >
      <DialogTitle bgcolor="inherit">
        <SearchGuidelinesInput
          searchText={searchText}
          setSearchText={setSearchText}
          handleClose={handleClose}
        />
      </DialogTitle>
      <DialogContent>
        <SearchGuidelinesSearchAndResults
          searchText={searchText}
          handleClose={handleCloseWithCleanup}
        />
      </DialogContent>
    </Dialog>
  );
}
