import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardContent,
  Link,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GuidelinePage,
  GuidelineSection,
  MenuItem,
} from '../../model/GuidelinePage';
import { SectionContent } from './SectionContent';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';
import { SectionHeading } from './SectionHeading';
import { PageHeading } from './PageHeading';

export type SectionParams = {
  section: GuidelineSection;
  allPages: Map<string, GuidelinePage>;
  singlePageRender?: boolean;
  imgErrorHandler: (uri: string) => void;
};

export function Section({
  section,
  allPages,
  singlePageRender = false,
  imgErrorHandler,
}: SectionParams): JSX.Element {
  const isMenusOnly = !(
    section.header ||
    section.content ||
    section.footer ||
    section.targetUrl
  );
  const { uriTransformer, getTargetUrlSection } =
    useGuidelinesProviderContext();

  let displaySection = { ...section };
  if (section.targetUrl) {
    const targetUrlSection = getTargetUrlSection(section.targetUrl);
    if (targetUrlSection) {
      displaySection = { ...section, ...targetUrlSection };
    }
  }

  return (
    <>
      {!isMenusOnly && (
        <CardContent key={displaySection.id}>
          <SectionHeading heading={displaySection.header} />
          <SectionContent
            content={displaySection.content}
            imgErrorHandler={imgErrorHandler}
          />
          {!!displaySection.footer && (
            <Typography variant="body2" color="textSecondary" component="p">
              {displaySection.footer}
            </Typography>
          )}
        </CardContent>
      )}
      {displaySection.menuItems && !singlePageRender && (
        <Box marginBottom="1rem" key={`${displaySection.id}-menus`}>
          {displaySection.menuItems.map((subMenuItem) =>
            buildMenuElement(subMenuItem, allPages, uriTransformer)
          )}
        </Box>
      )}
      {displaySection.menuItems &&
        singlePageRender &&
        buildInlineSubPages(section, allPages, uriTransformer, imgErrorHandler)}
    </>
  );
}

function buildInlineSubPages(
  section: GuidelineSection,
  allPages: Map<string, GuidelinePage>,
  uriTransformer: (uri: string) => string,
  imgErrorHandler: (uri: string) => void
) {
  const pageMenuItems = section.menuItems
    ? section.menuItems.filter((menuItem) => menuItem.targetType === 'page')
    : [];
  const nonPageMenuItems = section.menuItems
    ? section.menuItems.filter((menuItem) => menuItem.targetType !== 'page')
    : [];

  const nonPageMenuElements = (
    <Box marginBottom="1rem" key={`${section.id}-menus`}>
      {nonPageMenuItems.map((subMenuItem) =>
        buildMenuElement(subMenuItem, allPages, uriTransformer)
      )}
    </Box>
  );

  const pageElements = pageMenuItems.map((subMenuItem) => {
    const subPage =
      allPages.get(subMenuItem.targetKey) ??
      buildErrorPage(subMenuItem.targetKey);
    const pageHeadingElement = (
      <PageHeading heading={subPage.title} subheading={subPage.subheading} />
    );
    const pageSections = subPage.sections.map((subSection) => (
      <Section
        section={subSection}
        key={subSection.id}
        allPages={allPages}
        singlePageRender
        imgErrorHandler={imgErrorHandler}
      />
    ));
    return (
      <div
        key={subMenuItem.targetKey}
        className="border-light-gray border-l-4 pl-4"
      >
        {pageHeadingElement}
        {pageSections}
      </div>
    );
  });

  return (
    <>
      {nonPageMenuElements}
      {pageElements}
    </>
  );
}

function buildMenuElement(
  menuItem: MenuItem,
  allPages: Map<string, GuidelinePage>,
  uriTransformer: (uri: string) => string
) {
  switch (menuItem.targetType) {
    case 'page':
    case 'guideline':
    case 'microguide':
      return <MobilePageMenu menuItem={menuItem} key={menuItem.targetKey} />;

    case 'pdf':
      return (
        <PdfMenu
          menuItem={menuItem}
          uriTransformer={uriTransformer}
          key={menuItem.targetKey}
        />
      );

    case 'other':
      return buildDownloadMenu(
        menuItem,
        uriTransformer(menuItem.targetUrl ?? menuItem.targetKey)
      );

    default:
      return buildPlaceholderMenu(
        menuItem.title,
        menuItem.subtitle,
        menuItem.targetType
      );
  }
}

function buildPlaceholderMenu(
  title: string,
  subtitle: string | undefined,
  targetType: string
) {
  const message = `Not implemented '${targetType}' menus.`;
  const id = `${title}=${subtitle}-${targetType}`;
  return (
    <Accordion disableGutters elevation={0} square key={id}>
      <AccordionSummary
        expandIcon={<NavigateNextIcon />}
        aria-controls="panel1a-content"
        id={`${id}-summary`}
      >
        <Stack>
          <Typography>{title}</Typography>
          <Typography variant="body2" sx={{ color: 'GrayText' }}>
            {subtitle}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails id={`${id}-details`}>
        <Typography>{message}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

function buildDownloadMenu({ title, targetKey }: MenuItem, url: string) {
  const id = `${title}-${targetKey}`;
  return (
    <Link href={url} download={targetKey} id={id} padding={2} key={id}>
      {title}
    </Link>
  );
}

interface PdfMenuProps {
  menuItem: MenuItem;
  uriTransformer: (uri: string) => string;
}

function PdfMenu({ menuItem, uriTransformer }: PdfMenuProps): JSX.Element {
  const { title, subtitle } = menuItem;

  const id = `${title}=${subtitle}-pdf`;

  const pdfUrl = uriTransformer(menuItem.targetUrl ?? menuItem.targetKey);

  return (
    <AccordionSummary
      expandIcon={<OpenInNewIcon />}
      aria-controls="panel1a-content"
      id={`${id}-summary`}
      onClick={(event) => {
        event.preventDefault();
        window.open(pdfUrl);
      }}
      sx={{ backgroundColor: 'white' }}
    >
      <Stack>
        <Typography>
          <strong>{title}</strong>
        </Typography>
        <Typography variant="body2" sx={{ color: 'GrayText' }}>
          {subtitle}
        </Typography>
      </Stack>
    </AccordionSummary>
  );
}

interface MobilePageMenuProps {
  menuItem: MenuItem;
}

function MobilePageMenu({ menuItem }: MobilePageMenuProps): JSX.Element {
  let fullTo = '';

  const params = useParams();
  const navigate = useNavigate();

  if (menuItem.targetType === 'page') {
    const encodedUrl = params.encodedUrl ?? '';

    const pageId = menuItem.targetKey;
    const safeModule = encodeURIComponent(encodedUrl);
    const safePageId = encodeURIComponent(pageId);

    fullTo = `../${safeModule}?pageId=${safePageId}`;
  }
  if (menuItem.targetType === 'guideline') {
    // TODO: probably need to extract the path to the guideline from the URL
    const targetUrl = menuItem.targetUrl ?? menuItem.targetKey;
    const guidelinePath = targetUrl.replace('guideline://', '');
    fullTo = `../${guidelinePath}`;
  }
  if (menuItem.targetType === 'microguide') {
    const encodedUrl = params.encodedUrl ?? '';
    const pageId = menuItem.targetKey;
    const safePageId = encodeURIComponent(pageId);
    const encodedTargetUrl = encodeURIComponent(
      menuItem.targetUrl ?? menuItem.targetKey
    );
    const safeModule = encodeURIComponent(encodedUrl);

    fullTo = `../${safeModule}?pageId=${safePageId}&targetUrl=${encodedTargetUrl}`;
  }

  const styleProperties = menuItem.color
    ? { backgroundColor: menuItem.color }
    : {};

  return (
    <Accordion disableGutters elevation={0} square sx={styleProperties}>
      <AccordionSummary
        expandIcon={<ArrowForwardIosIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={(event) => {
          event.preventDefault();
          navigate(fullTo);
        }}
      >
        <Stack>
          <Typography>
            <strong>{menuItem.title}</strong>
          </Typography>
          <Typography variant="body2" sx={{ color: 'GrayText' }}>
            {menuItem.subtitle}
          </Typography>
        </Stack>
      </AccordionSummary>
    </Accordion>
  );
}

function buildErrorPage(sectionId: string): GuidelinePage {
  return {
    id: '',
    title: `Error loading sub-page`,
    sections: [
      {
        content: `No section found with ID '${sectionId}'`,
        menuItems: [],
      },
    ],
  };
}
