import { z } from 'zod';

export const GuidelinesIndexEntryJsonSchema = z.object({
  key: z.string(),
  md5: z.string(),
  module: z.string().optional(),
  guidelineName: z.string().optional(),
  path: z.string().optional(),
  extension: z.string().optional(),
  metadata_v2: z.record(z.union([z.string(), z.array(z.string())])).optional(),
});

export type GuidelinesIndexJsonEntry = z.infer<
  typeof GuidelinesIndexEntryJsonSchema
>;

const GuidelinesIndexEntrySchema = z.object({
  key: z.string(),
  md5: z.string(),
  module: z.string(),
  guidelineName: z.string(),
  path: z.string(),
  extension: z.string(),
  metadata: z.map(z.string(), z.union([z.string(), z.array(z.string())])),
});

export type GuidelinesIndexEntry = z.infer<typeof GuidelinesIndexEntrySchema>;

export type FileSystemHandles = {
  fileHandle: FileSystemFileHandle;
  parentFolderHandle: FileSystemDirectoryHandle;
};

export type FileSystemGuidelinesIndexEntry = GuidelinesIndexEntry &
  FileSystemHandles;

export const GuidelinesIndexJsonSchema = z.array(
  GuidelinesIndexEntryJsonSchema
);

const GuidelinesIndexSchema = z.object({
  lastModified: z.date(),
  indexEntries: z.array(GuidelinesIndexEntrySchema),
  guidelineCount: z.number(),
});

export type GuidelinesIndex = z.infer<typeof GuidelinesIndexSchema>;
