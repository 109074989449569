import medwayMicroguideData from './MedwayMicroguideLite.json';
import { GuidelineSection } from '../model/GuidelinePage';

export type MicroguideElement = {
  title: string;
  content: string;
  source: string;
};

const generalCss = `<style type="text/css">.TopTitle { display:none} .lineTitle { background-color: #4b76ae; color: white;text-align: center;padding:6px;font-size: 1em;} .SubTitle { background-color: #198F77; color: white;text-align: center;padding:6px;font-size: bigger;text-transform:capitalize;}</style>`;

const guides: Map<string, string> = new Map(
  Object.entries(medwayMicroguideData)
);

export function getMicroguide(guideUrl: string) {
  const indexName: string = decodeURIComponent(guideUrl).replace(/^\/+/, '');
  const guide: string | undefined = guides.get(`${indexName}`);

  if (guide) {
    const title = '';
    const guideWithMargin = `<div style="margin-top: 1rem;">${guide}</div>`;
    const content = generalCss + guideWithMargin;
    const source = 'Medway Microguide, v3.1.2, 9 September 2023';
    return { title, content, source } as MicroguideElement;
  }

  const availableMicroguideKeys = [...guides.keys()]
    .map((key) => `* ${key}`)
    .join('\n');
  return {
    title: guideUrl,
    content: `Microguide content not found for ${guideUrl}.\n\nAvailable microguides are:\n\n${availableMicroguideKeys}`,
    source: 'Medway Microguide, v3.1.2, 9 September 2023',
  } as MicroguideElement;
}

export function getMicroguideAsPageSection(guideUrlText: string) {
  const guideUrl = new URL(guideUrlText);
  const path = decodeURIComponent(guideUrl.hostname + guideUrl.pathname);
  const guide: MicroguideElement = getMicroguide(path);
  const contentWithoutFontSize = guide.content.replace(/font-size:.*?;/gm, '');
  return {
    id: path,
    content: contentWithoutFontSize,
    footer: guide.source,
  } as GuidelineSection;
}
