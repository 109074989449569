import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import { GuidelinesIndexEntry } from '../model/GuidelinesIndex';
import { useGuidelinesLoaderProviderContext } from '../services/GuidelinesLoaderProviderContext';
import {
  doesTitleIncludeFph,
  doesTitleIncludeHw,
  doesTitleIncludeWph,
  EnvironmentType,
  removeSiteSpecifierFromName,
  ThemeAndTitleContext,
} from './ThemeAndTitleProvider';
import { guidelinePathToUrlParam } from '../services/GuidelinePathToUrlParam';
import {
  buildGuidelinesByFolder,
  categorizeGuidelines,
} from '../services/GuidelinesIndexUtils';
import { GuidelinesAppBar } from './GuidelinesAppBar';
import { useInterval } from '../services/useInterval';
import { GuidelinesStatsMessage } from './GuidelinesStatsMessage';
import { useHistoryState } from '../services/useHistoryState';
import { AboveTheFoldMessge } from './AboveTheFoldMessage';
import { PullToRefresh } from './PullToRefresh';
import { CategoryIcon } from './CategoryIcon';
import { PlaceholderContents } from './PlaceholderContents';

interface ContentsProps {
  environment?: EnvironmentType;
  showAbout?: boolean;
}

export function Contents({
  environment = 'normal',
  showAbout = false,
}: ContentsProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [expandedCategoriesHistory, setExpandedCategoriesHistory] =
    useHistoryState<string[]>([], 'expandedCategories');
  const expandedCategoriesRef = useRef<string[]>(expandedCategoriesHistory);

  const { loadGuidelinesIndex } = useGuidelinesLoaderProviderContext();

  const [guidelineEntriesByCategory, setGuidelineEntriesByCategory] = useState(
    new Map<string, GuidelinesIndexEntry[]>()
  );

  const { setThemeFromName, setEnvironment } = useContext(ThemeAndTitleContext);
  useEffect(() => {
    setEnvironment(environment);
    setThemeFromName('');
  }, [environment, setEnvironment, setThemeFromName]);

  const getGuidelinesIndex = useCallback(async (): Promise<void> => {
    setLoading(true);

    try {
      if (!loadGuidelinesIndex) throw new Error('No guidelines provider');
      const guidelinesIndex = await loadGuidelinesIndex(showAbout);
      const tempGuidelineEntriesByCategory = categorizeGuidelines(
        guidelinesIndex.indexEntries,
        showAbout
      );
      setGuidelineEntriesByCategory(tempGuidelineEntriesByCategory);
    } catch (innerError) {
      const typedError = innerError as { message: string };
      setError(typedError.message);
    } finally {
      setLoading(false);
    }
  }, [loadGuidelinesIndex, showAbout]);

  const refreshGuidelinesIndex = useCallback(async (): Promise<void> => {
    try {
      // Clear the error message if we are refreshing
      if (error.length > 0) setError('');

      if (!loadGuidelinesIndex) throw new Error('No guidelines provider');
      const guidelinesIndex = await loadGuidelinesIndex(showAbout);
      const tempGuidelineEntriesByCategory = categorizeGuidelines(
        guidelinesIndex.indexEntries,
        showAbout
      );
      setGuidelineEntriesByCategory(tempGuidelineEntriesByCategory);
    } catch (innerError) {
      const typedError = innerError as { message: string };
      setError(typedError.message);
    }
  }, [loadGuidelinesIndex, error, showAbout]);

  useEffect(() => {
    getGuidelinesIndex();
  }, [getGuidelinesIndex, loadGuidelinesIndex, showAbout]);

  // Refresh the contents every 5 minutes
  const contentsRefreshIntervalMs = 5 * 60 * 1000; // 5 minutes
  useInterval(() => refreshGuidelinesIndex(), contentsRefreshIntervalMs);

  const { appTitle } = useContext(ThemeAndTitleContext);

  if (loading) {
    return <PlaceholderContents />;
  }

  if (error !== '') {
    return <li>{error}</li>;
  }

  const toggleIsExpanded = (categoryName: string) => {
    // For performance reasons we use a ref to keep track of the expanded
    // categories, rather than pure useState. There is a noticable delay when
    // using setState.
    const isExpanded = expandedCategoriesRef.current.includes(categoryName);
    if (isExpanded) {
      expandedCategoriesRef.current = expandedCategoriesRef.current.filter(
        (c) => c !== categoryName
      );
    } else {
      expandedCategoriesRef.current.push(categoryName);
    }
    // Save the expanded categories to the browser history for when we navigate
    // back to this page.
    setExpandedCategoriesHistory(expandedCategoriesRef.current);
  };
  const categoryElements: JSX.Element[] = buildCategoryElements(
    guidelineEntriesByCategory,
    expandedCategoriesRef.current,
    toggleIsExpanded
  );

  return (
    <div className="h-screen flex-grow bg-neutral-800 sm:bg-inherit">
      <GuidelinesAppBar appTitle={appTitle} showSearch />
      <PullToRefresh onRefresh={refreshGuidelinesIndex}>
        <GuidelinesStatsMessage />
        <AboveTheFoldMessge />
        {categoryElements}
      </PullToRefresh>
    </div>
  );
}

function buildCategoryElements(
  guidelineEntriesByCategory: Map<string, GuidelinesIndexEntry[]>,
  expandedCategories: string[],
  toggleIsExpanded: (categoryName: string) => void
) {
  const categoryElements: JSX.Element[] = [];

  guidelineEntriesByCategory.forEach((entriesInCategory, categoryName) => {
    const groupKey = categoryName;
    const entriesByFolder = buildGuidelinesByFolder(entriesInCategory);
    const allCategoryElements: JSX.Element[] = [];
    let isFirst = true;
    entriesByFolder.forEach((entriesInFolder, folderName) => {
      const hasFolderName = folderName.trim().length > 0;
      if (hasFolderName) {
        const folderHeaderKey = `${groupKey}/${folderName}`;
        const topMargin = isFirst ? 'mt-0' : 'mt-2';
        isFirst = false;
        // Remove any leading '!' characters from the folder name
        // These are used to force the folder to the top of the list but shouldn't be displayed
        const displayFolderName = folderName.replace(/^!+/g, '');

        // A folder starting with '!!' should be emphasised
        const shouldEmphasiseFolderName = folderName.startsWith('!!');

        allCategoryElements.push(
          <Grid
            item
            xs={12}
            key={folderHeaderKey}
            className={`${
              shouldEmphasiseFolderName ? 'bg-[#E00000]' : 'bg-[#116453]'
            } sm:bg-neutral-300 ${topMargin}`}
          >
            <Typography
              className={`${
                shouldEmphasiseFolderName
                  ? 'text-white'
                  : 'text-white sm:text-neutral-800'
              } ml-2 mr-2 text-lg`}
            >
              {displayFolderName}
            </Typography>
          </Grid>
        );
      }

      const buttonElements = entriesInFolder.map((guidelineEntry) =>
        buildGuidelineButton(guidelineEntry)
      );

      allCategoryElements.push(...buttonElements);
    });

    categoryElements.push(
      <div key={`${groupKey}-div`} data-cy={`category-${categoryName}`}>
        <Accordion
          key={groupKey}
          square
          defaultExpanded={expandedCategories.includes(categoryName)}
          onChange={() => toggleIsExpanded(categoryName)}
          className="bg-neutral-800 text-white sm:bg-white sm:text-neutral-800"
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon className="text-white sm:text-neutral-800" />
            }
            aria-controls={`${categoryName}-content`}
            id={`${categoryName}-header`}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <CategoryIcon categoryName={categoryName} width="64px" />
              <Typography className="text-2xl/tight">{categoryName}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails className="bg-neutral-700 pl-0 pr-0 pt-0 text-white sm:bg-white sm:pl-4 sm:pr-4 sm:text-neutral-800">
            <Grid container>{allCategoryElements}</Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  });
  return categoryElements;
}

function buildGuidelineButton(guidelineEntry: GuidelinesIndexEntry) {
  const safeUrl = guidelinePathToUrlParam(guidelineEntry.path);

  // TODO: Abstract this out of the core code
  const [guidelineName, badgeElement] = getSiteSpecificNameAndBadge(
    guidelineEntry.guidelineName
  );

  // Guidelines can have '!' at the start of the name so they sort to the top
  // If there is a second '!' at the start of the name, then replace it with '❗'
  const nameToDisplay = guidelineName.replace('!!', '❗').replace('!', '');

  // A guideline name starting with '!!' should be emphasised
  const shouldEmphasiseGuidelineName = guidelineName.startsWith('!!');

  return (
    <Grid item xs={12} sm={6} key={guidelineEntry.key}>
      <Button
        component={RouterLink}
        to={safeUrl}
        size="large"
        data-cy={`button-guideline-${safeUrl}`}
        className={`flex items-center justify-start normal-case leading-tight ${
          shouldEmphasiseGuidelineName
            ? 'text-[#E00000]'
            : 'text-neutral-100 sm:text-[#425563]'
        } pl-4 pr-4 text-lg/normal font-normal sm:text-[0.9375rem] sm:font-medium`}
      >
        {badgeElement}
        {nameToDisplay}
      </Button>
    </Grid>
  );
}

function getSiteSpecificNameAndBadge(
  guidelineName: string
): [string, JSX.Element] {
  const titleIncludesFph = doesTitleIncludeFph(guidelineName);
  const titleIncludesWph = doesTitleIncludeWph(guidelineName);
  const titleIncludesHw = doesTitleIncludeHw(guidelineName);

  const badgeElements: JSX.Element[] = [];

  if (titleIncludesFph) {
    badgeElements.push(
      <Chip size="small" color="frimley" label="FP" key="FP" className="mr-2" />
    );
  }

  if (titleIncludesWph) {
    badgeElements.push(
      <Chip size="small" color="wexham" label="WP" key="WP" className="mr-2" />
    );
  }

  if (titleIncludesHw) {
    badgeElements.push(
      <Chip
        size="small"
        color="heatherwood"
        label="HW"
        key="HW"
        className="mr-2"
      />
    );
  }

  const nameToDisplay = removeSiteSpecifierFromName(guidelineName);

  return [nameToDisplay, <>{badgeElements}</>];
}
