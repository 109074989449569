import { useEffect, useState } from 'react';
import { Section } from './Section';
import { GuidelinePage } from '../../model/GuidelinePage';
import { ImgErrorAlert } from './ImgErrorAlert';
import { MissingTitleAlert } from './MissingTitleAlert';
import { ContentProblemAlert } from './ContentProblemAlert';
import { applyTextReplacements } from './TextReplacements';

export type PageProps = {
  guideline: GuidelinePage;
  allPages: Map<string, GuidelinePage>;
  singlePageRender?: boolean;
  metaData?: Map<string, string>;
  textReplacements?: Map<string, string>;
};

export function Page({
  guideline,
  allPages,
  singlePageRender = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  metaData = new Map<string, string>(),
  textReplacements = new Map<string, string>(),
}: PageProps): JSX.Element {
  const [imgErrorUris, setImageErrorUris] = useState<string[]>([]);

  const imgErrorHandler = (uriString: string) => {
    if (!imgErrorUris.includes(uriString)) {
      setImageErrorUris([...imgErrorUris, uriString]);
    }
  };
  const [printing, setPrinting] = useState(false);

  useEffect(() => {
    const beforePrint = () => {
      setPrinting(true);
    };
    const afterPrint = () => {
      setPrinting(false);
    };

    window.addEventListener('beforeprint', beforePrint);
    window.addEventListener('afterprint', afterPrint);

    // Clean up event listeners
    return () => {
      window.removeEventListener('beforeprint', beforePrint);
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  return (
    <div
      className="bg-neutral-100 print:bg-white"
      data-cy={`guideline-sections-length: ${guideline.sections.length}`}
    >
      <ImgErrorAlert imgErrorUris={imgErrorUris} />
      <MissingTitleAlert title={guideline.title} />
      <ContentProblemAlert guideline={guideline} />

      {guideline.sections.map((section) => {
        const textReplacedSectionContent = applyTextReplacements(
          section.content,
          textReplacements
        );
        const newSection = { ...section, content: textReplacedSectionContent };
        return (
          <Section
            section={newSection}
            key={section.id}
            allPages={allPages}
            singlePageRender={singlePageRender || printing}
            imgErrorHandler={imgErrorHandler}
          />
        );
      })}
    </div>
  );
}
