import { useCallback, useEffect, useRef, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { GuidelinePage } from '../model/GuidelinePage';
import { useGuidelinesLoaderProviderContext } from '../services/GuidelinesLoaderProviderContext';
import { useInterval } from '../services/useInterval';
import { SectionContent } from './guideline_display/SectionContent';
import styles from './AboveTheFoldMessage.module.css';
import { applyTextReplacements } from './guideline_display/TextReplacements';

export function AboveTheFoldMessge(): JSX.Element {
  const { loadAboveTheFoldGuideline, loadGuidelinesIndex } =
    useGuidelinesLoaderProviderContext();

  const [aboveTheFoldGuideline, setAboveTheFoldGuideline] = useState<
    GuidelinePage | undefined
  >();

  const [textReplacements, setTextReplacements] =
    useState<Map<string, string>>();

  const getAboveTheFold = useCallback(async (): Promise<void> => {
    if (loadAboveTheFoldGuideline) {
      const guidelineAndSource = await loadAboveTheFoldGuideline();
      const isError =
        guidelineAndSource.pages.rootPage.title.startsWith('Error');
      if (!isError) {
        setAboveTheFoldGuideline(guidelineAndSource.pages.rootPage);
      }
    }
  }, [loadAboveTheFoldGuideline]);

  const getTextReplacements = useCallback(async (): Promise<void> => {
    if (loadGuidelinesIndex) {
      const guidelinesIndex = await loadGuidelinesIndex();
      const lastModifiedText =
        guidelinesIndex.lastModified.valueOf() > 0
          ? guidelinesIndex.lastModified.toLocaleDateString()
          : 'unknown';
      const tempTextReplacements = new Map<string, string>([
        ['GUIDELINE_COUNT', guidelinesIndex.guidelineCount.toString()],
        ['LAST_MODIFIED', lastModifiedText],
      ]);
      setTextReplacements(tempTextReplacements);
    }
  }, [loadGuidelinesIndex]);

  useEffect(() => {
    getTextReplacements();
    getAboveTheFold();
  }, [loadAboveTheFoldGuideline, getAboveTheFold, getTextReplacements]);

  // Refresh the message every 5 minutes
  const refreshIntervalMs = 5 * 60 * 1000; // 5 minutes
  useInterval(() => getAboveTheFold(), refreshIntervalMs);

  const [isTruncated, setIsTruncated] = useState(false);
  const truncatedTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    checkIfTruncated();

    const numberOfSections = aboveTheFoldGuideline?.sections?.length ?? 0;
    if (numberOfSections > 1) {
      setIsTruncated(true);
    }
  }, [aboveTheFoldGuideline]);

  window.addEventListener('resize', checkIfTruncated);

  if (
    !aboveTheFoldGuideline ||
    !textReplacements ||
    aboveTheFoldGuideline.title.startsWith('Error') ||
    aboveTheFoldGuideline.sections.length === 0 ||
    removeHtmlComments(aboveTheFoldGuideline.sections[0].content).trim()
      .length === 0
  ) {
    return <div />;
  }

  const textReplacedSectionContent = applyTextReplacements(
    aboveTheFoldGuideline.sections[0].content,
    textReplacements
  );

  return (
    <div className={styles.clear}>
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <div
              ref={truncatedTextRef}
              className="line-clamp-3 text-sm text-blue-700 md:line-clamp-3"
            >
              <div className={styles.markdown}>
                <SectionContent
                  content={textReplacedSectionContent}
                  imgErrorHandler={() => {
                    /* no-op */
                  }}
                  openLinksInNewTab
                />
              </div>
            </div>
            <p className="mt-3 text-sm md:ml-6 md:mt-0" hidden={!isTruncated}>
              <Link
                to="About-Above_The_Fold"
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
              >
                View More
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  function checkIfTruncated() {
    const isNowTruncated =
      truncatedTextRef.current &&
      truncatedTextRef.current.scrollHeight >
        truncatedTextRef.current.clientHeight;

    setIsTruncated(isNowTruncated ?? false);
  }
}

function removeHtmlComments(html: string): string {
  return html.replace(/<!--[\s\S]*?-->/g, '');
}
