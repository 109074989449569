import { useContext } from 'react';
import { GuidelinesAppBar } from './GuidelinesAppBar';
import { ThemeAndTitleContext } from './ThemeAndTitleProvider';

export function PlaceholderContents(): JSX.Element {
  const { appTitle } = useContext(ThemeAndTitleContext);
  const widths = [190, 114, 164, 190, 114];
  const placeholderCategories = widths.map((width, index) => (
    <div
      className="flex items-center space-x-4 pl-2"
      key={`placeholder-${width}-${index}`}
    >
      <div className="h-16 w-16 rounded-lg bg-neutral-700 sm:bg-neutral-200" />
      <div
        className="h-10 rounded bg-neutral-700 sm:bg-neutral-200"
        style={{ width }}
      />
    </div>
  ));

  return (
    <div className="h-screen flex-grow bg-neutral-800 sm:bg-inherit">
      <GuidelinesAppBar appTitle={appTitle} />
      <div className="animate-pulse space-y-4 pt-4 transition-all delay-300">
        {placeholderCategories}
      </div>
    </div>
  );
}
