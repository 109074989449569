import { useCallback, ChangeEvent } from 'react';
import { Input, InputAdornment, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

type SearchGuidelinesInputProps = {
  searchText: string;
  setSearchText: (newSearchText: string) => void;
  handleClose: () => void;
};

export function SearchGuidelinesInput({
  searchText,
  setSearchText,
  handleClose,
}: SearchGuidelinesInputProps): JSX.Element {
  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newSearchText = event.target.value;
      setSearchText(newSearchText);
    },
    [setSearchText]
  );

  return (
    <Input
      placeholder="Search…"
      inputProps={{ 'aria-label': 'search' }}
      onChange={handleSearchChange}
      value={searchText}
      autoFocus
      fullWidth
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton type="reset" onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
}
