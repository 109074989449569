import { XCircleIcon } from '@heroicons/react/20/solid';
import { useSearchParams } from 'react-router-dom';
import { GuidelinePage } from '../../model/GuidelinePage';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';

type ContentProblemAlertProps = {
  guideline: GuidelinePage;
};

export function ContentProblemAlert({
  guideline,
}: ContentProblemAlertProps): JSX.Element {
  const { endsWithUriTransformer } = useGuidelinesProviderContext();
  const isFileSystemProvider = endsWithUriTransformer !== undefined;

  const [searchParams] = useSearchParams();

  const hasShowErrorsParam = searchParams.get('showErrors') === 'true';

  const showErrors = hasShowErrorsParam || isFileSystemProvider;

  if (!showErrors) return <div />;

  const contentProblems: Set<string> = new Set();

  guideline.sections.forEach((section) => {
    // const hasUnicodeLt = section.content.includes('&#x3C;');
    // const hasUnicodeGt = section.content.includes('&#x3E;');

    const hasHtmlEntityLt = section.content.includes('\\&lt;');
    if (hasHtmlEntityLt) {
      contentProblems.add(
        `Found '\\&lt;' in content. Probably should be '<'. Will display as '&#x3C;'`
      );
    }

    const hasHtmlEntityGt = section.content.includes('\\&gt;');
    if (hasHtmlEntityGt) {
      contentProblems.add(
        `Found '\\&gt;' in content. Probably should be '>'. Might display as '&#x3E;'`
      );
    }
  });

  if (contentProblems.size === 0)
    return <div data-cy="hasHtmlEntities: false" />;

  return (
    <div className="rounded-md bg-red-50 p-4" data-cy="hasHtmlEntities: true">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="clear-styles">
            <div className="text-sm font-medium text-red-800">
              This guideline contains characters that might not display
              correctly.
            </div>
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">
              {Array.from(contentProblems).map((problem) => (
                <li key={problem} className="tailwind">
                  {problem}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
