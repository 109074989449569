import { XCircleIcon } from '@heroicons/react/20/solid';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useGuidelinesProviderContext } from '../../services/GuidelinesProviderContext';
import {
  doesTitleIncludeFph,
  doesTitleIncludeWph,
  doesTitleIncludeHw,
} from '../ThemeAndTitleProvider';

type MissingTitleAlertProps = {
  title: string;
};

export function MissingTitleAlert({
  title,
}: MissingTitleAlertProps): JSX.Element {
  const { endsWithUriTransformer } = useGuidelinesProviderContext();
  const isFileSystemProvider = endsWithUriTransformer !== undefined;

  const [searchParams] = useSearchParams();

  const hasShowErrorsParam = searchParams.get('showErrors') === 'true';

  const showErrors = hasShowErrorsParam || isFileSystemProvider;

  if (!showErrors) return <div />;

  if (title.length > 0) return <div data-cy="isMissingTitle: false" />;

  return (
    <div className="rounded-md bg-red-50 p-4" data-cy="isMissingTitle: true">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="clear-styles">
            <div className="text-sm font-medium text-red-800">
              This guideline is missing a title. It would display above ☝️
            </div>
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">
              <li className="tailwind">
                Check the markdown file starts with: <br />
                <code>
                  # <em>Your Title</em>
                </code>
              </li>
              <PredictTitleFromPagePath />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function PredictTitleFromPagePath() {
  const path = useLocation().pathname;

  const guidelineNameMatches = path.match(/.*?-!?(.*)/);
  const guidelineName = guidelineNameMatches?.[1] ?? '';

  if (guidelineName.length === 0) {
    return <div />;
  }

  const titleIncludesFph = doesTitleIncludeFph(path);
  const titleIncludesWph = doesTitleIncludeWph(path);
  const titleIncludesHw = doesTitleIncludeHw(path);

  const nameWithoutSpecifiers = guidelineName
    .replace(/([(\- ]*[wf]ph\W*)/gim, '')
    .replace(/^([(\- ]*hwd?\W+)/gim, '')
    .replace(/([(\- ]+hwd?\W*)$/gim, '');

  const title = `# ${nameWithoutSpecifiers.replaceAll('_', ' ')} ${
    titleIncludesFph ? '[At Frimley Park Hospital]' : ''
  } ${titleIncludesWph ? '[At Wexham Park Hospital]' : ''} ${
    titleIncludesHw ? '[At Heatherwood Hospital]' : ''
  }`.trim();

  return (
    <li className="tailwind">
      From the path of this page, we think the title might be:
      <br />
      <code>{title}</code>
    </li>
  );
}
