import { ThemeProvider } from '@mui/material';
import { createContext, useCallback, useState } from 'react';
import {
  frimleyParkTheme,
  mainTheme,
  stagingTheme,
  wexhamParkTheme,
} from '../Themes';

export type BrandingType = 'normal' | 'staging' | 'frimley' | 'wexham';
export type EnvironmentType = 'normal' | 'staging';

interface TitlesAndSetters {
  appTitle: string;
  setAppTitle: (appTitle: string) => void;
  currentBranding: BrandingType;
  setCurrentBranding: (branding: BrandingType) => void;
  environment: EnvironmentType;
  setEnvironment: (environment: EnvironmentType) => void;
  setThemeFromName: (guidelineTitle: string) => void;
  removeSiteSpecifierFromName: (guidelineTitle: string) => string;
}

const emptyTitleAndSetter: TitlesAndSetters = {
  appTitle: process.env.REACT_APP_TITLE ?? '',
  setAppTitle: () => {
    /* do nothing */
  },
  currentBranding: 'normal',
  setCurrentBranding: () => {
    /* do nothing */
  },
  environment: 'normal',
  setEnvironment: () => {
    /* do nothing */
  },
  setThemeFromName: () => {
    /* do nothing */
  },
  removeSiteSpecifierFromName: (guidelineTitle) => guidelineTitle,
};

export const ThemeAndTitleContext =
  createContext<TitlesAndSetters>(emptyTitleAndSetter);

export function ThemeAndTitleProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [appTitle, setAppTitle] = useState('');
  const [currentBranding, setCurrentBranding] =
    useState<BrandingType>('normal');
  const [environment, setEnvironment] = useState<EnvironmentType>('normal');

  let currentTheme = mainTheme;
  switch (currentBranding) {
    case 'staging':
      currentTheme = stagingTheme;
      break;
    case 'frimley':
      currentTheme = frimleyParkTheme;
      break;
    case 'wexham':
      currentTheme = wexhamParkTheme;
      break;
    case 'normal':
    default:
      break;
  }

  const setThemeFromName = useCallback(
    (guidelineName: string) => {
      const titleIncludesFph = doesTitleIncludeFph(guidelineName);
      const titleIncludesWph = doesTitleIncludeWph(guidelineName);
      const titleIncludesHw = doesTitleIncludeHw(guidelineName);

      const isStaging = environment === 'staging';

      let brandingToUse: BrandingType = isStaging ? 'staging' : 'normal';
      brandingToUse =
        titleIncludesFph && !titleIncludesWph && !titleIncludesHw
          ? 'frimley'
          : brandingToUse;
      brandingToUse =
        titleIncludesWph && !titleIncludesFph && !titleIncludesHw
          ? 'wexham'
          : brandingToUse;

      const stagingPrefix = isStaging ? 'FOR REVIEW ONLY—' : '';

      const siteSpecificWarnings = [
        titleIncludesFph ? 'Frimley Park' : '',
        titleIncludesWph ? 'Wexham Park' : '',
        titleIncludesHw ? 'Heatherwood' : '',
      ]
        .filter((x) => x !== '')
        .join(' and ');

      const newAppTitle =
        siteSpecificWarnings.length > 0
          ? `${siteSpecificWarnings} Only`
          : process.env.REACT_APP_TITLE ?? '';

      setAppTitle(`${stagingPrefix}${newAppTitle}`);
      setCurrentBranding(brandingToUse);
    },
    [environment]
  );

  return (
    <ThemeAndTitleContext.Provider
      value={{
        appTitle,
        setAppTitle,
        currentBranding,
        setCurrentBranding,
        environment,
        setEnvironment,
        setThemeFromName,
        removeSiteSpecifierFromName,
      }}
    >
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </ThemeAndTitleContext.Provider>
  );
}

export function doesTitleIncludeWph(guidelineName: string) {
  const wexhamParkSpecificMatch = guidelineName.match(/(\W*wph\W*)/gim);

  const titleIncludesWph = Boolean(wexhamParkSpecificMatch);
  return titleIncludesWph;
}

export function doesTitleIncludeFph(guidelineName: string) {
  const frimleyParkSpecificMatch = guidelineName.match(/(\W*fph\W*)/gim);
  const titleIncludesFph = Boolean(frimleyParkSpecificMatch);
  return titleIncludesFph;
}

export function doesTitleIncludeHw(guidelineName: string) {
  const matchBefore = guidelineName.match(/^(\W*hwd?\W*)/gim);
  const matchAfter = guidelineName.match(/(\W*hwd?\W*)$/gim);
  const matchHwd = guidelineName.match(/(\W*hwd\W*)/gim);

  const titleIncludesHw =
    Boolean(matchBefore) || Boolean(matchAfter) || Boolean(matchHwd);
  return titleIncludesHw;
}

export function removeSiteSpecifierFromName(guidelineName: string): string {
  const nameWithoutSpecifiers = guidelineName
    .replace(/([(\- ]*[wf]ph[^(-]*)/gim, '')
    .replace(/^([(\- ]*hwd?[^(-]+)/gim, '')
    .replace(/([(\- ]+hwd?[^(-]*)$/gim, '');

  const canSafelyRemoveSpecifiers = !nameWithoutSpecifiers.endsWith('and');
  return canSafelyRemoveSpecifiers ? nameWithoutSpecifiers : guidelineName;
}
