import { useCallback, useEffect, useState } from 'react';
import { useGuidelinesLoaderProviderContext } from '../services/GuidelinesLoaderProviderContext';
import { useInterval } from '../services/useInterval';

export type GuidelinesStatsMessageProps = {
  showIntranetGuidelinesLink?: boolean;
  intranetGuidelinesUrl?: string;
  intranetImageUrl?: string;
};

export function GuidelinesStatsMessage({
  showIntranetGuidelinesLink = false,
  intranetGuidelinesUrl = '',
  intranetImageUrl = '',
}: GuidelinesStatsMessageProps): JSX.Element {
  const { loadGuidelinesIndex } = useGuidelinesLoaderProviderContext();

  const [linkExists, setLinkExists] = useState(false);

  const [guidelineCount, setGuidelineCount] = useState<string>('');
  const [lastModifiedText, setLastModifiedText] = useState<string>('');

  const getGuidelineStats = useCallback(async (): Promise<void> => {
    if (loadGuidelinesIndex) {
      const guidelinesIndex = await loadGuidelinesIndex();
      setGuidelineCount(guidelinesIndex.guidelineCount.toString());

      const tempLastModifiedText =
        guidelinesIndex.lastModified.valueOf() > 0
          ? guidelinesIndex.lastModified.toLocaleDateString()
          : 'unknown';
      setLastModifiedText(tempLastModifiedText);
    }
  }, [loadGuidelinesIndex]);

  useEffect(() => {
    getGuidelineStats();
  }, [getGuidelineStats]);

  // Refresh the message every 5 minutes
  const refreshIntervalMs = 5 * 60 * 1000; // 5 minutes
  useInterval(() => getGuidelineStats(), refreshIntervalMs);

  const statsMessageElement = (
    <span className="text-xs">
      {guidelineCount} guidelines, last updated {lastModifiedText}.{' '}
    </span>
  );

  const haveStatsData = guidelineCount !== '' && lastModifiedText !== '';

  const intranetAvailableElement = (
    <p className="text-xs">
      More guidelines available on the{' '}
      <a
        href={intranetGuidelinesUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="underline"
      >
        Clinical Guidelines site
      </a>
    </p>
  );
  const intranetNotAvailableElement = (
    <span className="text-xs">
      More guidelines available on the Clinical Guidelines site on the intranet
    </span>
  );

  const intranetMessageElement = linkExists
    ? intranetAvailableElement
    : intranetNotAvailableElement;

  return (
    <div className="hidden sm:block">
      {showIntranetGuidelinesLink && (
        <img
          alt="Anaesthetics Logo"
          src={intranetImageUrl}
          title="URL Checker"
          onLoad={() => setLinkExists(true)}
          onError={() => setLinkExists(false)}
          style={{ display: 'none' }}
        />
      )}
      {haveStatsData && statsMessageElement}
      {showIntranetGuidelinesLink && intranetMessageElement}
    </div>
  );
}
