import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PrintIcon from '@mui/icons-material/Print';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Menu, MenuItem } from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { forwardRef, useEffect, useState } from 'react';
import { Device } from '@capacitor/device';
import { SearchGuidelines } from './SearchGuidelines';

interface GuidelinesAppBarProps {
  appTitle: string;
  showSearch?: boolean;
  showNav?: boolean;
  onPrint?: () => void;
}

const homeRouterLink = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'>
>((itemProps, ref) => <RouterLink to="/" ref={ref} {...itemProps} />);
homeRouterLink.displayName = 'homeRouterLink';

const aboutRouterLink = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'>
>((itemProps, ref) => (
  <RouterLink to="/About-About" ref={ref} {...itemProps} />
));
aboutRouterLink.displayName = 'aboutRouterLink';

const authoringRouterLink = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'>
>((itemProps, ref) => <RouterLink to="/authoring" ref={ref} {...itemProps} />);
authoringRouterLink.displayName = 'authoringRouterLink';

export function GuidelinesAppBar({
  appTitle,
  showSearch = false,
  showNav = false,
  onPrint,
}: GuidelinesAppBarProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const [devicePlatform, setDevicePlatform] = useState('web');

  useEffect(() => {
    const populateDevicePlatform = async () => {
      const info = await Device.getInfo();

      setDevicePlatform(info.platform);
    };

    populateDevicePlatform();
  }, []);

  const showAuthoringMenuOption = devicePlatform === 'web';

  const handleAboutMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const menuId = 'primary-about-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component={homeRouterLink} onClick={handleMenuClose}>
        Home
      </MenuItem>
      <MenuItem component={aboutRouterLink} onClick={handleMenuClose}>
        About
      </MenuItem>
      {showAuthoringMenuOption && (
        <MenuItem component={authoringRouterLink} onClick={handleMenuClose}>
          Authoring
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0}>
        <Toolbar>
          {!showNav && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleAboutMenuOpen}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          {showNav && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="ios back"
              onClick={() => {
                window.history.back();
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          <Link
            underline="hover"
            variant="h6"
            noWrap
            component={homeRouterLink}
            color="white"
            flexGrow={1}
            data-cy="app-title"
          >
            {appTitle}
          </Link>
          {showSearch && <SearchGuidelines />}
          {onPrint && (
            <IconButton onClick={onPrint}>
              <PrintIcon htmlColor="white" />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}
