import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactGA from 'react-ga4';
import preval from 'preval.macro';
import './index.css';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { App as CapacitorApp } from '@capacitor/app';
import reportWebVitals from './reportWebVitals';
import { App } from './App';

// Initialising Google Analytics for Medway
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? '');
const buildDate: Date = preval`module.exports = new Date();`;
ReactGA.set({
  buildDate: buildDate.toLocaleString(),
  title: `Build:${buildDate.toLocaleString()}`,
});

const container = document.getElementById('root');
if (!container) {
  throw new Error('No root element');
}
const root = createRoot(container);
root.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <App />
    </StyledEngineProvider>
  </StrictMode>
);

CapacitorApp.addListener('backButton', ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
