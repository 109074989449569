import { Button, Grid, Chip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { GuidelinesIndexEntry } from '../model/GuidelinesIndex';
import { guidelinePathToUrlParam } from '../services/GuidelinePathToUrlParam';
import {
  doesTitleIncludeFph,
  doesTitleIncludeHw,
  doesTitleIncludeWph,
  removeSiteSpecifierFromName,
} from './ThemeAndTitleProvider';
import { useGuidelinesLoaderProviderContext } from '../services/GuidelinesLoaderProviderContext';
import { categorizeGuidelines } from '../services/GuidelinesIndexUtils';

interface SearchGuidelinesSearchAndResultsProps {
  searchText: string;
  handleClose: () => void;
}

export function SearchGuidelinesSearchAndResults({
  searchText,
  handleClose,
}: SearchGuidelinesSearchAndResultsProps): JSX.Element {
  const [error, setError] = useState('');

  const { loadGuidelinesIndex } = useGuidelinesLoaderProviderContext();

  const [
    guidelineEntriesByCategoryAndFolder,
    setGuidelineEntriesByCategoryAndFolder,
  ] = useState(new Map<string, GuidelinesIndexEntry[]>());

  /*
  I can build a map of keywords and the guidelines that have those keywords.
  Then when I search the keywords I can filter the keywords to those that start
  with or contain the search text. Then the results are those guidelines
  associated with they keyword fragment. We'd have to be careful about
  overwhelming the user with results.
  */
  const [guidelineEntriesByKeyword, setGuidelineEntriesByKeyword] = useState(
    new Map<string, GuidelinesIndexEntry[]>()
  );

  const getGuidelinesIndex = useCallback(async (): Promise<void> => {
    try {
      if (!loadGuidelinesIndex) throw new Error('No guidelines provider');
      const guidelinesIndex = await loadGuidelinesIndex();
      const tempGuidelineEntriesByCategoryAndFolder = categorizeGuidelines(
        guidelinesIndex.indexEntries,
        false,
        'CategoryAndSubfolder'
      );
      setGuidelineEntriesByCategoryAndFolder(
        tempGuidelineEntriesByCategoryAndFolder
      );

      const tempGuidelineEntriesByKeyword = buildKeywordsSearchIndex(
        guidelinesIndex.indexEntries
      );
      setGuidelineEntriesByKeyword(tempGuidelineEntriesByKeyword);
    } catch (innerError) {
      const typedError = innerError as { message: string };
      setError(typedError.message);
    }
  }, [loadGuidelinesIndex]);

  useEffect(() => {
    getGuidelinesIndex();
  }, [getGuidelinesIndex]);

  const [searchResultsElement, setSearchResultsElement] = useState(<div />);

  const performSearch = useCallback(async (): Promise<void> => {
    // Use setTimeout to allow the display to render before doing the search
    setTimeout(() => {
      const tempSearchResultsElement = buildSearchResults(
        guidelineEntriesByCategoryAndFolder,
        guidelineEntriesByKeyword,
        searchText,
        handleClose
      );
      setSearchResultsElement(tempSearchResultsElement);
    }, 0);
  }, [guidelineEntriesByCategoryAndFolder, handleClose, searchText]);

  useEffect(() => {
    performSearch();
  }, [guidelineEntriesByCategoryAndFolder, performSearch, searchText]);

  if (error !== '') {
    return <li>{error}</li>;
  }

  return <>{searchResultsElement}</>;
}

function buildKeywordsSearchIndex(
  guidelinesIndexEntries: GuidelinesIndexEntry[]
) {
  // Populate guidelineEntriesByKeyword based on reading the 'keywords'
  // field in the metadata property of each guideline. The keywords are
  // comma separated, so need to split them and then add the guideline to
  // the map for each keyword.
  const tempGuidelineEntriesByKeyword = new Map<
    string,
    GuidelinesIndexEntry[]
  >();
  guidelinesIndexEntries.forEach((indexEntry) => {
    // Convert indexEntry.metadata to a Map<string, string>
    const metadataMap = new Map<string, string>();
    if (indexEntry.metadata) {
      indexEntry.metadata.forEach((value, key) => {
        let stringValue = '';
        if (Array.isArray(value)) {
          stringValue = value.join(';');
        } else if (typeof value === 'string') {
          stringValue = value;
        }
        metadataMap.set(key, stringValue);
      });
    }

    const keywords = metadataMap.get('Keywords')?.split(/[,;]/) ?? [];
    keywords.forEach((keyword) => {
      const trimmedKeyword = keyword.trim();
      if (trimmedKeyword.length === 0) {
        return;
      }
      if (!tempGuidelineEntriesByKeyword.has(trimmedKeyword)) {
        tempGuidelineEntriesByKeyword.set(trimmedKeyword, []);
      }
      const entriesForKeyword =
        tempGuidelineEntriesByKeyword.get(trimmedKeyword);
      if (entriesForKeyword) {
        entriesForKeyword.push(indexEntry);
      }
    });
  });
  return tempGuidelineEntriesByKeyword;
}

function buildSearchResults(
  guidelineEntriesByCategoryAndFolder: Map<string, GuidelinesIndexEntry[]>,
  guidelineEntriesByKeyword: Map<string, GuidelinesIndexEntry[]>,
  searchText: string,
  handleClose: () => void
) {
  const containsSearchResults = filterGuidelinesIndex(
    guidelineEntriesByCategoryAndFolder,
    searchText.trim(),
    'contains'
  );

  const containsKeywordSearchResults = filterOutKeywordIndexDuplicates(
    filterGuidelinesIndexByKeyword(
      guidelineEntriesByKeyword,
      searchText.trim(),
      'contains'
    )
  );

  // Doing a word match over the entire index is expensive, so only do it when
  // there are more than 2 search characters, and then only filter the
  // contains results
  let matchesWordSearchResultElements: JSX.Element[] = [];
  let matchesWordSearchResults: Map<string, GuidelinesIndexEntry[]> = new Map();
  const haveSearchText = searchText.trim().length > 1;
  if (haveSearchText) {
    matchesWordSearchResults = filterGuidelinesIndex(
      containsSearchResults,
      searchText.trim(),
      'exact'
    );
    matchesWordSearchResultElements = buildSearchResultElements(
      matchesWordSearchResults,
      searchText.trim(),
      handleClose
    );
  }

  let matchesKeywordSearchResultElements: JSX.Element[] = [];
  let matchesKeywordSearchResults: Map<string, GuidelinesIndexEntry[]> =
    new Map();
  if (haveSearchText) {
    matchesKeywordSearchResults = filterOutKeywordIndexDuplicates(
      filterGuidelinesIndexByKeyword(
        containsKeywordSearchResults,
        searchText.trim(),
        'exact'
      )
    );
    matchesKeywordSearchResultElements = buildKeywordSearchResultElements(
      matchesKeywordSearchResults,
      searchText.trim(),
      handleClose
    );
  }

  // Filter the duplicates out of the contains results
  const containsSearchResultsWithoutWordMatches = filterOutIndexDuplicates(
    containsSearchResults,
    matchesWordSearchResults
  );

  const containsKeywordSearchResultsWithoutWordMatches =
    filterOutIndexDuplicates(
      containsKeywordSearchResults,
      matchesKeywordSearchResults
    );

  const containsSearchResultElements = buildSearchResultElements(
    containsSearchResultsWithoutWordMatches,
    searchText.trim(),
    handleClose
  );

  const containsKeywordSearchResultElements = buildKeywordSearchResultElements(
    containsKeywordSearchResultsWithoutWordMatches,
    searchText.trim(),
    handleClose
  );

  const hasResults =
    containsSearchResultElements.length > 0 ||
    matchesWordSearchResultElements.length > 0 ||
    containsKeywordSearchResultElements.length > 0 ||
    matchesKeywordSearchResultElements.length > 0;

  const hasKeywordResults =
    containsKeywordSearchResultElements.length > 0 ||
    matchesKeywordSearchResultElements.length > 0;
  const keywordResultElements = [
    buildHeaderElement('Keyword Matches'),
    ...matchesKeywordSearchResultElements,
    ...containsKeywordSearchResultElements,
  ];

  return hasResults ? (
    <>
      {hasKeywordResults && keywordResultElements}
      {matchesWordSearchResultElements}
      {containsSearchResultElements}
    </>
  ) : (
    <Typography>{`No matches for '${searchText}'`}</Typography>
  );
}

function guidelineNameContainsSearchText(
  indexEntry: GuidelinesIndexEntry,
  searchText: string
) {
  return indexEntry.guidelineName
    .toLocaleLowerCase()
    .includes(searchText.toLocaleLowerCase());
}

function escapeRegExp(text: string) {
  return text.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

function guidelineNameContainsExactWord(
  text: string,
  searchText: string
): boolean {
  const regex = new RegExp(`\\b${escapeRegExp(searchText)}\\b`, 'i');

  return regex.test(text);
}

function filterGuidelinesIndex(
  guidelineEntriesByCategory: Map<string, GuidelinesIndexEntry[]>,
  searchText: string,
  searchType: 'exact' | 'contains'
) {
  const filteredEntries = new Map<string, GuidelinesIndexEntry[]>();

  guidelineEntriesByCategory.forEach(
    (entriesInCategoryAndSubfolder, categoryAndSubfolderName) => {
      const indexEntriesContainingSearchText =
        entriesInCategoryAndSubfolder.filter((indexEntry) =>
          searchType === 'contains'
            ? guidelineNameContainsSearchText(indexEntry, searchText)
            : guidelineNameContainsExactWord(
                indexEntry.guidelineName,
                searchText
              )
        );

      const hasResultsInThisFolder =
        indexEntriesContainingSearchText.length > 0;

      if (hasResultsInThisFolder) {
        filteredEntries.set(
          categoryAndSubfolderName,
          indexEntriesContainingSearchText
        );
      }
    }
  );

  return filteredEntries;
}

function filterGuidelinesIndexByKeyword(
  guidelineEntriesByKeyword: Map<string, GuidelinesIndexEntry[]>,
  searchText: string,
  searchType: 'exact' | 'contains'
) {
  const filteredEntries = new Map<string, GuidelinesIndexEntry[]>();

  if (searchText.trim().length === 0) {
    return filteredEntries;
  }

  guidelineEntriesByKeyword.forEach((entriesForKeyword, keyword) => {
    if (keyword.trim().length === 0) {
      return;
    }
    const hasMatchingKeyword =
      searchType === 'contains'
        ? keyword.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
        : keyword.toLocaleLowerCase() === searchText.toLocaleLowerCase();

    if (hasMatchingKeyword) {
      filteredEntries.set(keyword, entriesForKeyword);
    }
  });

  return filteredEntries;
}

function filterOutIndexDuplicates(
  toFilter: Map<string, GuidelinesIndexEntry[]>,
  toCompare: Map<string, GuidelinesIndexEntry[]>
) {
  const filteredEntries = new Map<string, GuidelinesIndexEntry[]>();

  toFilter.forEach(
    (entriesInCategoryAndSubfolder, categoryAndSubfolderName) => {
      let filteredIndex = entriesInCategoryAndSubfolder;
      if (toCompare.has(categoryAndSubfolderName)) {
        const possibleDuplicates =
          toCompare.get(categoryAndSubfolderName) ?? [];
        filteredIndex = entriesInCategoryAndSubfolder.filter(
          (indexEntry) => !possibleDuplicates.includes(indexEntry)
        );
      }
      if (filteredIndex.length > 0) {
        filteredEntries.set(categoryAndSubfolderName, filteredIndex);
      }
    }
  );

  return filteredEntries;
}

function filterOutKeywordIndexDuplicates(
  toFilter: Map<string, GuidelinesIndexEntry[]>
) {
  const filteredEntries = new Map<string, GuidelinesIndexEntry[]>();
  const seenGuidelinesIndex: GuidelinesIndexEntry[] = [];

  toFilter.forEach((entriesForKeyword, keyword) => {
    entriesForKeyword.forEach((entry) => {
      if (!seenGuidelinesIndex.includes(entry)) {
        seenGuidelinesIndex.push(entry);
        const currentEntriesForKeyword = filteredEntries.get(keyword) ?? [];
        filteredEntries.set(keyword, [...currentEntriesForKeyword, entry]);
      }
    });
  });

  return filteredEntries;
}

function buildSearchResultElements(
  guidelineEntriesByCategory: Map<string, GuidelinesIndexEntry[]>,
  searchText: string,
  handleClose: () => void
) {
  const categoryElements: JSX.Element[] = [];

  guidelineEntriesByCategory.forEach(
    (indexEntriesContainingSearchText, categoryAndSubfolderName) => {
      const folderElements = buildFolderResultElements(
        indexEntriesContainingSearchText,
        searchText,
        categoryAndSubfolderName,
        handleClose
      );

      categoryElements.push(...folderElements);
    }
  );

  return categoryElements;
}

function buildKeywordSearchResultElements(
  guidelineEntriesByKeyword: Map<string, GuidelinesIndexEntry[]>,
  searchText: string,
  handleClose: () => void
) {
  const keywordElements = [...guidelineEntriesByKeyword.entries()].flatMap(
    (mapEntry) => {
      const keyword = mapEntry[0];
      const indexEntriesContainingSearchText = mapEntry[1];
      return buildKeywordResultElements(
        indexEntriesContainingSearchText,
        searchText,
        keyword,
        handleClose
      );
    }
  );

  return keywordElements;
}

// Would be good to read this rather than hard code it
const dialogContentPadding = '24px';

function buildFolderResultElements(
  entriesInCategoryAndSubfolder: GuidelinesIndexEntry[],
  searchText: string,
  categoryAndSubfolderName: string,
  handleClose: () => void
) {
  const headerElement = buildHeaderElement(categoryAndSubfolderName);

  const buttonElements = entriesInCategoryAndSubfolder.map((guidelineEntry) =>
    buildGuidelineButton(guidelineEntry, searchText, false, handleClose)
  );

  const folderElements = [headerElement, ...buttonElements];
  return folderElements;
}

function buildHeaderElement(text: string) {
  const headerElement = (
    <Grid
      item
      xs={12}
      key={text}
      bgcolor="lightgrey"
      style={{
        // Go full width by setting our margin to the opposite
        // of the parent's padding
        marginLeft: `-${dialogContentPadding}`,
        marginRight: `-${dialogContentPadding}`,
        paddingLeft: '1rem',
      }}
    >
      <Typography variant="body2">{text}</Typography>
    </Grid>
  );
  return headerElement;
}

function buildKeywordResultElements(
  indexEntriesForKeyword: GuidelinesIndexEntry[],
  searchText: string,
  keyword: string,
  handleClose: () => void
) {
  const filteredEntries = indexEntriesForKeyword.filter((indexEntry) => {
    const keywordsEntry = indexEntry.metadata.get('Keywords') ?? '';

    let keywords = '';
    if (Array.isArray(keywordsEntry)) {
      keywords = keywordsEntry.join(',');
    } else if (typeof keywordsEntry === 'string') {
      keywords = keywordsEntry;
    }

    const containsKeyword = keywords
      .toLocaleLowerCase()
      .includes(keyword.toLocaleLowerCase());
    return containsKeyword;
  });
  const buttonElements = filteredEntries.map((guidelineEntry) =>
    buildGuidelineButton(guidelineEntry, searchText, true, handleClose)
  );

  return buttonElements;
}

function buildGuidelineButton(
  guidelineEntry: GuidelinesIndexEntry,
  searchText: string,
  showKeywords: boolean,
  handleClose: () => void
) {
  const safeUrl = guidelinePathToUrlParam(guidelineEntry.path);

  // TODO: Abstract this out of the core code
  const [nameToDisplay, badgeElement] = getSiteSpecificNameAndBadge(
    guidelineEntry.guidelineName
  );

  let keywords: string[] = [];
  const keywordsEntry = guidelineEntry.metadata.get('Keywords') ?? '';

  if (Array.isArray(keywordsEntry)) {
    // If it's an array, split each string in the array by ',' or ';' and flatten
    keywords = keywordsEntry.flatMap((kw) => kw.split(/[,;]/));
  } else if (typeof keywordsEntry === 'string') {
    // If it's a string, split it by ',' or ';'
    keywords = keywordsEntry.split(/[,;]/);
  }

  // Remove any leading/trailing whitespace from each keyword
  keywords = keywords.map((kw) => kw.trim());

  const keywordsToDisplay = keywords.map((keyword) => {
    const isMatchingKeyword = keyword
      .toLocaleLowerCase()
      .includes(searchText.toLocaleLowerCase());

    return (
      <div
        className={`mr-1 mt-1 rounded-full bg-slate-200 px-2 py-1 text-xs text-slate-500 ${
          isMatchingKeyword ? 'ring ring-inset' : ''
        }`}
      >
        {keyword}
      </div>
    );
  });

  const nameToDisplayWithEmphasis = showKeywords ? (
    <span>{nameToDisplay}</span>
  ) : (
    boldMatchedText(nameToDisplay, searchText)
  );

  const key = `${guidelineEntry.key}-${showKeywords ? 'keywords' : 'name'}}`;
  return (
    <Grid item xs={12} sm={6} key={key}>
      <Button
        component={RouterLink}
        to={safeUrl}
        style={{ textTransform: 'none', justifyContent: 'flex-start' }}
        onClick={handleClose}
      >
        <Grid container direction="column">
          <Grid item xs={12}>
            <div>
              {badgeElement}
              {nameToDisplayWithEmphasis}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="flex flex-wrap">
              {showKeywords && keywordsToDisplay}
            </div>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
}

function boldMatchedText(text: string, searchText: string) {
  const parts = text.split(new RegExp(`(${searchText})`, 'gi'));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === searchText.toLowerCase() ? (
          <span key={`${part}-${index}`} className="font-bold text-slate-500">
            {part}
          </span>
        ) : (
          part
        )
      )}
    </span>
  );
}

function getSiteSpecificNameAndBadge(
  guidelineName: string
): [string, JSX.Element] {
  const titleIncludesFph = doesTitleIncludeFph(guidelineName);
  const titleIncludesWph = doesTitleIncludeWph(guidelineName);
  const titleIncludesHw = doesTitleIncludeHw(guidelineName);

  const badgeElements: JSX.Element[] = [];

  if (titleIncludesFph) {
    badgeElements.push(
      <Chip
        size="small"
        color="frimley"
        label="FP"
        key="FP"
        sx={{ marginRight: '0.5rem' }}
      />
    );
  }

  if (titleIncludesWph) {
    badgeElements.push(
      <Chip
        size="small"
        color="wexham"
        label="WP"
        key="WP"
        sx={{ marginRight: '0.5rem' }}
      />
    );
  }

  if (titleIncludesHw) {
    badgeElements.push(
      <Chip
        size="small"
        color="heatherwood"
        label="HW"
        key="HW"
        sx={{ marginRight: '0.5rem' }}
      />
    );
  }

  const nameToDisplay = removeSiteSpecifierFromName(guidelineName).replace(
    '!',
    ''
  );

  return [nameToDisplay, <>{badgeElements}</>];
}
